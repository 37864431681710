import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import {
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormGroup,
    Label,
    Form,
    Alert
} from "reactstrap";

import FormPrintOthers from "../forms/FormPrintOthers";

//http
import http from "../../services/httpService";
import { apiUrl } from "../../config.json";

const OthersData = props => {
    const { others } = props;

    useEffect(() => {
        const patient_id = props.match.params.patient_id;
        setInputs({
            ...inputs,
            patient_id
        });
    }, []);

    const [currentCase, setCurrentCase] = useState("");
    const [editModal, setEditModal] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [localMessage, setLocalMessage] = useState("");

    const [inputs, setInputs] = useState({
        patient_id: "",
        consultation_date: new Date().toDateString(),
        referred_by: "",
        procedure: "",
        result: ""
    });

    function toggleModal() {
        setModalIsOpen(!modalIsOpen);
    }

    function handleChange(e) {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        });
    }

    function handleDelete(id) {
        http.delete(`${apiUrl}/others/${id}`)
            .then(response => {
                console.log(response, id);
                props.deleteOthers(id);
            })
            .catch(err => console.log(err));
    }

    function handleCreate(e) {
        e.preventDefault();
        http.post(`${apiUrl}/others`, inputs)
            .then(response => {
                console.log(response);
                setLocalMessage("Consultation successfully Added.");

                props.addOthers(response.data.others);

                var winPrint = window.open(
                    "",
                    "",
                    "left=55,top=0,width=1200,height=600,toolbar=0,scrollbars=0,status=0"
                );
                winPrint.document.write(
                    `<html><head><link rel="stylesheet"
                    type="text/css"
                    href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossorigin="anonymous"/>
                    <style>
                        *{
                            list-style-type: none;
                        }

                        body{
                            height: 1375px;
                        }

                        .othersBody{
                            font-size:22px;
                            position:relative;
                        }

                        .doctorSignature{
                            margin-left: 50px;
                        }

                        .footer{
                            position: absolute;
                            top: 1210px;
                            left: 0;
                            width: 100%;
                        }

                        hr{
                            border: 1px solid #0f0b30;
                        }

                        .header_divider {
                            border: 2px solid #0f0b30;
                        }
                    </style>
                    </head><body>` +
                        document.getElementById("othersPrintContent")
                            .innerHTML +
                        `</body></html>`
                );
                winPrint.document.close();
                setTimeout(() => {
                    winPrint.focus();
                    winPrint.print();
                    winPrint.close();
                }, 1000);

                setTimeout(() => {
                    setLocalMessage("");
                    setModalIsOpen(false);
                }, 3000);
            })
            .catch(err => {
                console.log(err);
            });
    }

    function editOthers(id) {
        http.get(`${apiUrl}/others/${id}`)
            .then(response => {
                console.log(response.data);
                const {
                    patient_id,
                    consultation_date,
                    others_id,
                    referred_by,
                    procedure,
                    result
                } = response.data.others;
                setInputs({
                    patient_id,
                    consultation_date: new Date(
                        consultation_date
                    ).toDateString(),
                    referred_by,
                    procedure,
                    result
                });
                setCurrentCase(others_id);
                setEditModal(!editModal);
            })
            .catch(err => console.log(err));
    }

    function saveEditOthers(e) {
        e.preventDefault();
        http.put(`${apiUrl}/others/${currentCase}`, inputs)
            .then(response => {
                setLocalMessage("Consultation edited.");
                props.updateOthers({ currentCase, inputs });

                setTimeout(() => {
                    setLocalMessage("");
                    setEditModal(false);
                }, 3000);
            })
            .catch(err => console.log(err));
    }

    const othersRecord =
        others.length === 0 ? (
            <tr>
                <td colSpan="3" className="text-center">
                    No others record
                </td>
            </tr>
        ) : (
            others.map(other => (
                <tr key={other.others_id}>
                    <td>{new Date(other.consultation_date).toDateString()}</td>

                    <td className="text-nowrap">
                        <Button
                            title="Edit Consultation"
                            color="warning"
                            size="sm"
                            className="mr-1"
                            onClick={() => editOthers(other.others_id)}
                        >
                            <i className="fa fa-edit" />
                        </Button>
                        <Button
                            title="Delete Consultation"
                            color="danger"
                            size="sm"
                            className="mr-1"
                            onClick={() => handleDelete(other.others_id)}
                        >
                            <i className="fa fa-trash" />
                        </Button>
                    </td>
                </tr>
            ))
        );

    return (
        <>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th className="text-nowrap">Consultation Date</th>
                        <th
                            style={{
                                width: "100px"
                            }}
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>{othersRecord}</tbody>
            </Table>
            <hr />

            <Modal isOpen={modalIsOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                    Other Consultation
                </ModalHeader>
                <ModalBody>
                    {localMessage ? (
                        <Alert color="success">{localMessage}</Alert>
                    ) : null}
                    <Form className="others-form">
                        <Label for="dateNow" className="mr-3">
                            Consultation Date:
                        </Label>
                        <strong id="dateNow">
                            {new Date().toDateString()}
                        </strong>

                        <FormGroup className="form-label-group">
                            <Input
                                type="text"
                                id="referred"
                                placeholder="Physician"
                                name="referred_by"
                                onChange={handleChange}
                            />
                            <Label for="referred">Referred by:</Label>
                        </FormGroup>

                        <FormGroup>
                            <Label for="procedure">Procedure</Label>
                            <Input
                                onChange={handleChange}
                                name="procedure"
                                type="textarea"
                                id="procedure"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="result">Result</Label>
                            <Input
                                onChange={handleChange}
                                name="result"
                                type="textarea"
                                id="result"
                            />
                        </FormGroup>
                        <Button
                            color="primary"
                            type="submit"
                            onClick={handleCreate}
                        >
                            Save Consultation
                        </Button>
                    </Form>
                    <div id="othersPrintContent" className="container d-none">
                        <FormPrintOthers
                            patient={props.patient}
                            inputs={inputs}
                        />
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

            {/* edit modal */}
            <Modal isOpen={editModal} toggle={() => setEditModal(false)}>
                <ModalHeader toggle={() => setEditModal(false)}>
                    Other Consultation
                </ModalHeader>
                <ModalBody>
                    {localMessage ? (
                        <Alert color="success">{localMessage}</Alert>
                    ) : null}

                    <Form className="others-form">
                        <Label for="dateNow" className="mr-3">
                            Consultation Date:
                        </Label>
                        {inputs.consultation_date ? (
                            <strong id="dateNow">
                                {inputs.consultation_date}
                            </strong>
                        ) : null}
                        <FormGroup className="form-label-group">
                            <Input
                                type="text"
                                id="referred"
                                placeholder="Physician"
                                name="referred_by"
                                onChange={handleChange}
                                value={inputs.referred_by}
                            />
                            <Label for="referred">Referred by:</Label>
                        </FormGroup>
                        <FormGroup>
                            <Label for="procedure">Procedure</Label>
                            <Input
                                onChange={handleChange}
                                name="procedure"
                                type="textarea"
                                id="procedure"
                                value={inputs.procedure}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="result">Result</Label>
                            <Input
                                onChange={handleChange}
                                name="result"
                                type="textarea"
                                id="result"
                                value={inputs.result}
                            />
                        </FormGroup>
                        <Button
                            color="warning"
                            type="submit"
                            onClick={saveEditOthers}
                        >
                            Edit Consultation
                        </Button>{" "}
                        <Button
                            color="secondary"
                            onClick={() => {
                                var winPrint = window.open(
                                    "",
                                    "",
                                    "left=55,top=0,width=1200,height=600,toolbar=0,scrollbars=0,status=0"
                                );
                                winPrint.document.write(
                                    `<html><head><link rel="stylesheet"
                                    type="text/css"
                                    href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                                    crossorigin="anonymous"/>
                                    <style>
                                        *{
                                            list-style-type: none;
                                        }
                
                                        body{
                                            height: 1375px;
                                        }
            
                                        .othersBody{
                                            font-size:22px;
                                            position:relative;
                                        }
            
                                        .doctorSignature{
                                            margin-left: 50px;
                                        }
            
                                        .footer{
                                            position: absolute;
                                            top: 1210px;
                                            left: 0;
                                            width: 100%;
                                        }

                                        hr{
                                            border: 1px solid #0f0b30;
                                        }
            
                                        .header_divider {
                                            border: 2px solid #0f0b30;
                                        }
                                    </style>
                                    </head><body>` +
                                        document.getElementById(
                                            "othersPrintContent"
                                        ).innerHTML +
                                        `</body></html>`
                                );
                                winPrint.document.close();
                                setTimeout(() => {
                                    winPrint.focus();
                                    winPrint.print();
                                    winPrint.close();
                                }, 1000);
                            }}
                        >
                            Print
                        </Button>
                        <div
                            id="othersPrintContent"
                            className="container d-none"
                        >
                            <FormPrintOthers
                                patient={props.patient}
                                inputs={inputs}
                            />
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={() => setEditModal(false)}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

            <Button color="primary" onClick={toggleModal} className="mt-2">
                <i className="fa fa-plus" aria-hidden="true" /> New Others
                Consultation
            </Button>
        </>
    );
};
export default withRouter(OthersData);
