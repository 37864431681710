import React, { Component } from "react";
import {
    Row,
    Col,
    Form,
    FormText,
    Label,
    Input,
    Button,
    Alert,
    FormGroup
} from "reactstrap";

import FormPrintPapsmear from "./FormPrintPapsmear";

import http from "../../services/httpService";
import { apiUrl } from "../../config.json";

export default class FormEditPapsmear extends Component {
    state = {
        showAlert: false,
        formLoading: false,
        patient: {
            name: "",
            age: "",
            civilStatus: ""
        },
        formData: {
            cytopathology_id: "",
            patient_id: "",
            physician: "",
            ac_no: "",
            consultation_date: "",
            hospital: "",
            specimen: "",
            specimen_cervical_smear: false,
            specimen_vaginal_smear: false,
            specimen_others: "",
            pertinent_data: "",
            specimen_adequacy: "",
            general_categorization_date_requested: "",
            general_categorization_negative: false,
            general_categorization_epithelial: false,
            general_categorization_others: false,
            automated_review: "",
            diagnosis_non_neoplastic: "",
            diagnosis_negative_intraepithelial: "",
            organisms: "",
            organisms_count: "",
            non_neoplastic_findings_reactive_assoc_with: "false",
            non_neoplastic_findings_reactive: false,
            non_neoplastic_findings_inflammation: false,
            non_neoplastic_findings_radiation: false,
            non_neoplastic_findings_uid: false,
            non_neoplastic_findings_other_nonneoplastic: false,
            non_neoplastic_findings_atrophy: false,
            non_neoplastic_findings_others: false,
            inflammation: "",
            epi_abnormalities: false,
            epi_abnormalities_squamouscell: false,
            epi_abnormalities_squamouscell_atypical: false,
            epi_abnormalities_squamouscell_atypical_ascus: false,
            epi_abnormalities_squamouscell_atypical_asch: false,
            epi_abnormalities_squamouscell_lsil: false,
            epi_abnormalities_squamouscell_lsil_hpv: false,
            epi_abnormalities_squamouscell_lsil_cin1: false,
            epi_abnormalities_squamouscell_hsil: false,
            epi_abnormalities_squamouscell_hsil_cin2: false,
            epi_abnormalities_squamouscell_hsil_cin3: false,
            epi_abnormalities_squamouscell_hsil_withfeature: false,
            epi_abnormalities_squamouscell_carcinoma: false,
            epi_abnormalities_glandularcell: false,
            epi_abnormalities_glandularcell_atypical: false,
            epi_abnormalities_glandularcell_atypical_endocervical: false,
            epi_abnormalities_glandularcell_atypical_endometrial: false,
            epi_abnormalities_glandularcell_atypical_situ: false,
            epi_abnormalities_glandularcell_atypicalglandular: false,
            epi_abnormalities_glandularcell_atypicalglandular_endocervical: false,
            epi_abnormalities_glandularcell_atypicalglandular_glandular: false,
            epi_abnormalities_glandularcell_adenocarcinoma: false,
            epi_abnormalities_glandularcell_adenocarcinoma_endocervical: false,
            epi_abnormalities_glandularcell_adenocarcinoma_endometrial: false,
            epi_abnormalities_glandularcell_adenocarcinoma_extrauterine: false,
            epi_abnormalities_glandularcell_adenocarcinoma_notspecified: false,
            other_malignant_neoplasms: "",
            notes_and_suggestions: ""
        },
        formErrors: {
            consultation_date: ""
        }
    };

    //axios cancellation
    CancelToken = http.cancelToken;
    source = this.CancelToken.source();

    componentWillUnmount() {
        if (this.closeMessageTimer) {
            clearTimeout(this.closeMessageTimer);
        }
        this.source.cancel("Operation updating patient cancelled!");
    }

    convertToTimeInput = time => {
        let d = new Date(time);

        let date = d.getDate() < 9 ? "0" + d.getDate() : d.getDate();
        let month =
            d.getMonth() + 1 < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
        let year = d.getFullYear();

        return year + "-" + month + "-" + date;
    };

    componentDidMount() {
        const {
            lastname,
            firstname,
            middlename,
            age,
            civil_status
        } = this.props.patient;

        let { patient, formData } = { ...this.state };

        patient.name = `${lastname} ${firstname} ${middlename}`;
        patient.age = age;
        patient.civilStatus = civil_status;

        formData = this.props.papsmear;
        formData.consultation_date = this.convertToTimeInput(
            formData.consultation_date
        );
        formData.general_categorization_date_requested = formData.general_categorization_date_requested
            ? this.convertToTimeInput(
                  formData.general_categorization_date_requested
              )
            : "";

        this.setState({
            patient,
            formData
        });
    }

    handleChangeInput = e => {
        let formData = { ...this.state.formData };
        formData[e.target.name] = e.target.value;
        this.setState({ formData });
    };

    handleChangeCheckbox = e => {
        let formData = { ...this.state.formData };
        formData[e.target.name] = !formData[e.target.name];
        this.setState({ formData });
    };

    handleBlur = e => {
        let formErrors = { ...this.state.formErrors };
        if (e.target.value.trim() !== "") {
            formErrors[e.target.name] = "";
        }
        this.setState({ formErrors });
    };

    validateInput = () => {
        const { consultation_date } = this.state.formData;

        let errors = {};
        if (consultation_date.trim() === "") {
            errors.consultation_date = "Please select consultation date";
        }
        return Object.keys(errors).length === 0 ? null : errors;
    };

    handleSubmit = e => {
        e.preventDefault();
        const formErrors = this.validateInput();
        if (formErrors) {
            this.setState({
                formErrors
            });
            alert(
                "There's an error(s) on the form. Please scan the form and check."
            );
        } else {
            this.setState(prevState => ({
                formLoading: !prevState.formLoading
            }));

            let formData = { ...this.state.formData };
            const { cytopathology_id } = formData;
            delete formData.cytopathology_id;

            http.put(apiUrl + `/papsmear/${cytopathology_id}`, formData, {
                cancelToken: this.source.token
            })
                .then(response => {
                    this.props.editPapsmear({ ...this.state.formData });

                    this.setState(prevState => ({
                        showAlert: true,
                        formLoading: !prevState.formLoading
                    }));

                    //hide success message after 3 secs
                    this.closeMessageTimer = setTimeout(() => {
                        this.setState({
                            showAlert: false
                        });
                    }, 3000);
                })
                .catch(error => {
                    alert(error);
                    alert("on form edit");
                });
        }
    };

    render() {
        const { formData, patient } = this.state;

        const editPapsmearRecordButton = this.state.formLoading ? (
            <Button type="submit" disabled color="warning">
                Updating ...
            </Button>
        ) : (
            <Button type="submit" color="warning">
                <i className="fa fa-edit" /> Edit Consultation Report
            </Button>
        );

        return (
            <>
                <Form className="papsmear-form" onSubmit={this.handleSubmit}>
                    <Row>
                        <Col md={4}>
                            <FormGroup className="form-label-group">
                                <Input
                                    type="text"
                                    id="1"
                                    placeholder="Name"
                                    disabled
                                    value={patient.name}
                                />
                                <Label for="1">Name</Label>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className="form-label-group">
                                <Input
                                    type="text"
                                    id="2"
                                    placeholder="Physician"
                                    name="physician"
                                    onChange={this.handleChangeInput}
                                    value={formData.physician}
                                />
                                <Label for="2">Physician</Label>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className="form-label-group">
                                <Input
                                    type="date"
                                    id="3"
                                    name="consultation_date"
                                    onChange={this.handleChangeInput}
                                    value={formData.consultation_date}
                                    invalid={
                                        this.state.formErrors.consultation_date
                                            ? true
                                            : false
                                    }
                                    onBlur={this.handleBlur}
                                />
                                <Label for="3">Consultation Date</Label>
                                <FormText>
                                    {this.state.formErrors.consultation_date}
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup className="form-label-group">
                                <Input
                                    type="Number"
                                    id="4"
                                    placeholder="Age"
                                    disabled
                                    value={patient.age}
                                />
                                <Label for="4">Age</Label>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className="form-label-group">
                                <Input
                                    type="text"
                                    id="5"
                                    name="hospital"
                                    placeholder="Hospital"
                                    onChange={this.handleChangeInput}
                                    value={formData.hospital}
                                />
                                <Label for="5">Hospital</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup className="form-label-group">
                                <Input
                                    type="text"
                                    id="6"
                                    placeholder="Civil Status"
                                    disabled
                                    value={patient.civilStatus}
                                />
                                <Label for="6">Civil Status</Label>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className="form-label-group">
                                <Input
                                    type="text"
                                    id="7"
                                    name="ac_no"
                                    placeholder="AC No."
                                    onChange={this.handleChangeInput}
                                    value={formData.ac_no}
                                />
                                <Label for="7">AC No.</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} />
                        <Col md={4} />
                    </Row>
                    <hr />
                    {/* specimen */}
                    <Row>
                        <Col md={4}>
                            <Row>
                                <Col md={5}>Specimen/s: </Col>
                                <Col md={7}>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="text"
                                            id="8"
                                            name="specimen"
                                            placeholder="slide/s"
                                            onChange={this.handleChangeInput}
                                            value={formData.specimen}
                                        />
                                        <Label for="8">slide/s</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="ml-4">
                                        <Input
                                            type="checkbox"
                                            name="specimen_cervical_smear"
                                            id="9"
                                            onChange={this.handleChangeCheckbox}
                                            checked={
                                                formData.specimen_cervical_smear
                                            }
                                        />
                                        <Label for="9">Cervical Smear</Label>
                                        <br />
                                        <Input
                                            type="checkbox"
                                            name="specimen_vaginal_smear"
                                            id="10"
                                            onChange={this.handleChangeCheckbox}
                                            checked={
                                                formData.specimen_vaginal_smear
                                            }
                                        />
                                        <Label for="10">Viginal Smear</Label>
                                    </FormGroup>
                                </Col>
                                <Col md={8}>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="text"
                                            id="12"
                                            name="specimen_others"
                                            placeholder="Others"
                                            onChange={this.handleChangeInput}
                                            value={formData.specimen_others}
                                        />
                                        <Label for="12">Others</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* end of specimen */}

                    {/* Pertinent data */}
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-label-group">
                                <Input
                                    type="text"
                                    id="13"
                                    name="pertinent_data"
                                    placeholder="Pertinent Data"
                                    onChange={this.handleChangeInput}
                                    value={formData.pertinent_data}
                                />
                                <Label for="13">Pertinent Data</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    {/* Pertinent data */}

                    {/* Specimen Adequacy */}
                    <Row className="px-2">
                        <Col md={3}>
                            <strong>Specimen Adequacy</strong>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    name="specimen_adequacy"
                                    id="14"
                                    onChange={this.handleChangeInput}
                                    value="satisfactory"
                                    checked={
                                        formData.specimen_adequacy ===
                                        "satisfactory"
                                            ? true
                                            : false
                                    }
                                />
                                <Label for="14">Satisfactory</Label>
                            </FormGroup>
                        </Col>
                        <Col md={7}>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    id="15"
                                    name="specimen_adequacy"
                                    onChange={this.handleChangeInput}
                                    value="unsatisfactory"
                                    checked={
                                        formData.specimen_adequacy ===
                                        "unsatisfactory"
                                            ? true
                                            : false
                                    }
                                />
                                <Label for="15">Unsatisfactory</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* End of Specimen Adequacy */}

                    {/* General Categorization */}
                    <div className="px-2 mb-2">
                        <strong>General Categorization</strong>
                    </div>
                    <Row>
                        <Col md={7}>
                            <FormGroup className="mx-5">
                                <Input
                                    type="checkbox"
                                    name="general_categorization_negative"
                                    id="16"
                                    onChange={this.handleChangeCheckbox}
                                    checked={
                                        formData.general_categorization_negative
                                    }
                                />
                                <Label for="16">
                                    Negative for Intraepithelial or Malignancy
                                </Label>
                                <br />
                                <Input
                                    type="checkbox"
                                    name="general_categorization_epithelial"
                                    id="17"
                                    onChange={this.handleChangeCheckbox}
                                    checked={
                                        formData.general_categorization_epithelial
                                    }
                                />
                                <Label for="17">
                                    Epithelial Cell Abnormality
                                </Label>
                                <br />
                                <Input
                                    type="checkbox"
                                    name="general_categorization_others"
                                    id="18"
                                    onChange={this.handleChangeCheckbox}
                                    checked={
                                        formData.general_categorization_others
                                    }
                                />
                                <Label for="18">Others</Label>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className="form-label-group mt-5">
                                <Input
                                    type="date"
                                    id="19"
                                    name="general_categorization_date_requested"
                                    onChange={this.handleChangeInput}
                                    value={
                                        formData.general_categorization_date_requested
                                    }
                                />
                                <Label for="19">Date Requested</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-label-group">
                                <Input
                                    type="text"
                                    id="20"
                                    name="automated_review"
                                    placeholder="Automated Review"
                                    onChange={this.handleChangeInput}
                                    value={formData.automated_review}
                                />
                                <Label for="20">Automated Review</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* End of General Categorization */}
                    <hr />
                    {/* Descriptive Interpretaion Diagnosis */}
                    <div className="px-2 mb-2">
                        <strong>Descriptive Interpretation Diagnosis:</strong>
                    </div>
                    {/* Diagnosis */}
                    <Row>
                        <Col md={4}>
                            <FormGroup className="mx-5">
                                <Input
                                    type="checkbox"
                                    name="diagnosis_non_neoplastic"
                                    id="21"
                                    onChange={this.handleChangeCheckbox}
                                    checked={formData.diagnosis_non_neoplastic}
                                />
                                <Label for="21">Non-Neoplastic</Label>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    name="diagnosis_negative_intraepithelial"
                                    id="22"
                                    onChange={this.handleChangeCheckbox}
                                    checked={
                                        formData.diagnosis_negative_intraepithelial
                                    }
                                />
                                <Label for="22">
                                    Negative for Intraepithelial Lesion or
                                    Malignacy
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* End of Diagnosis */}
                    {/* Organisms */}
                    <Row>
                        <Col md={1} />
                        <Col md={4}>
                            <FormGroup className="form-label-group">
                                <Input
                                    type="text"
                                    id="23"
                                    name="organisms"
                                    placeholder="Organisms"
                                    onChange={this.handleChangeInput}
                                    value={formData.organisms}
                                />
                                <Label for="23">Organisms</Label>
                            </FormGroup>
                        </Col>
                        <Col md={7}>
                            <div className="ml-3 mt-3">
                                <Row>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Input
                                                type="checkbox"
                                                name="organisms_count"
                                                id="24"
                                                onChange={
                                                    this.handleChangeInput
                                                }
                                                value="Few"
                                                checked={
                                                    formData.organisms_count ===
                                                    "Few"
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <Label for="24">Few</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Input
                                                type="checkbox"
                                                name="organisms_count"
                                                id="25"
                                                onChange={
                                                    this.handleChangeInput
                                                }
                                                value="Minimal"
                                                checked={
                                                    formData.organisms_count ===
                                                    "Minimal"
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <Label for="25">Minimal</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Input
                                                type="checkbox"
                                                name="organisms_count"
                                                id="26"
                                                onChange={
                                                    this.handleChangeInput
                                                }
                                                value="Moderate"
                                                checked={
                                                    formData.organisms_count ===
                                                    "Moderate"
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <Label for="26">Moderate</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Input
                                                type="checkbox"
                                                name="organisms_count"
                                                id="27"
                                                onChange={
                                                    this.handleChangeInput
                                                }
                                                value="Many"
                                                checked={
                                                    formData.organisms_count ===
                                                    "Many"
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <Label for="27">Many</Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    {/* End of Organisms */}
                    {/* Non-Neoplastic Findings */}
                    <div className="mx-5 mb-2">
                        <strong>Non-Neoplastic Findings:</strong>
                    </div>
                    <div style={{ marginLeft: "114px" }}>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Input
                                        type="checkbox"
                                        id="28"
                                        name="non_neoplastic_findings_reactive"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.non_neoplastic_findings_reactive
                                        }
                                    />
                                    <Label for="28">
                                        Reactive cellular changes associated
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup className="form-label-group">
                                    <Input
                                        type="text"
                                        id="29"
                                        name="non_neoplastic_findings_reactive_assoc_with"
                                        placeholder="with:"
                                    />
                                    <Label for="29">with:</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "-17px" }}>
                            <Col md={4}>
                                <FormGroup>
                                    <Input
                                        type="checkbox"
                                        id="30"
                                        name="non_neoplastic_findings_inflammation"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.non_neoplastic_findings_inflammation
                                        }
                                    />
                                    <Label for="30">Inflamation</Label>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup className="form-label-group">
                                    <Input
                                        type="text"
                                        id="31"
                                        name="inflammation"
                                        placeholder="inflamation:"
                                        onChange={this.handleChangeInput}
                                        value={formData.inflammation}
                                    />
                                    <Label for="31">inflamation:</Label>
                                </FormGroup>
                            </Col>

                            <Col md={2}>
                                <FormGroup className="mt-3 ml-2">
                                    <Input
                                        type="checkbox"
                                        id="32"
                                        name="non_neoplastic_findings_radiation"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.non_neoplastic_findings_radiation
                                        }
                                    />
                                    <Label for="32">Radiation</Label>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup className="mt-3">
                                    <Input
                                        type="checkbox"
                                        id="33"
                                        name="non_neoplastic_findings_uid"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.non_neoplastic_findings_uid
                                        }
                                    />
                                    <Label for="33">IUD</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <FormGroup>
                                    <Input
                                        type="checkbox"
                                        id="34"
                                        name="non_neoplastic_findings_other_nonneoplastic"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.non_neoplastic_findings_other_nonneoplastic
                                        }
                                    />
                                    <Label for="34">
                                        <strong>
                                            Other Non-Neoplastic Findings:
                                        </strong>
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Input
                                        type="checkbox"
                                        id="35"
                                        name="non_neoplastic_findings_atrophy"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.non_neoplastic_findings_atrophy
                                        }
                                    />
                                    <Label for="35">Atrophy</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "-17px" }}>
                            <Col md={12}>
                                <FormGroup>
                                    <Input
                                        type="checkbox"
                                        id="36"
                                        name="non_neoplastic_findings_others"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.non_neoplastic_findings_others
                                        }
                                    />
                                    <Label for="36">
                                        <strong>Others:</strong>
                                        &nbsp;Ednomentrial cells (in woman more
                                        than 40 years of age)
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    {/* End of Descriptive Interpretaion Diagnosis */}
                    <div className="mx-5">
                        <FormGroup style={{ marginLeft: "26px" }}>
                            <Input
                                type="checkbox"
                                id="37"
                                name="epi_abnormalities"
                                onChange={this.handleChangeCheckbox}
                                checked={formData.epi_cell_abnormalities}
                            />
                            <Label for="37">
                                <strong>Epithelial Cell Abnomalities</strong>
                            </Label>
                        </FormGroup>
                    </div>
                    <div style={{ marginLeft: "114px" }}>
                        <FormGroup>
                            <Input
                                type="checkbox"
                                id="38"
                                name="epi_abnormalities_squamouscell"
                                onChange={this.handleChangeCheckbox}
                                checked={
                                    formData.epi_abnormalities_squamouscell
                                }
                            />
                            <Label for="38">
                                <strong>Squamous Cell</strong>
                            </Label>
                        </FormGroup>

                        <div style={{ marginLeft: "50px" }}>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    id="39"
                                    name="epi_abnormalities_squamouscell_atypical"
                                    onChange={this.handleChangeCheckbox}
                                    checked={
                                        formData.epi_abnormalities_squamouscell_atypical
                                    }
                                />
                                <Label for="39">Atypical squamos cells:</Label>
                            </FormGroup>
                            <div style={{ marginLeft: "50px" }}>
                                <FormGroup>
                                    <Input
                                        type="checkbox"
                                        id="40"
                                        name="epi_abnormalities_squamouscell_atypical_ascus"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.epi_abnormalities_squamouscell_atypical_ascus
                                        }
                                    />
                                    <Label for="40">
                                        of undetermined significance (AS-CUS)
                                    </Label>
                                    <br />
                                    <Input
                                        type="checkbox"
                                        id="41"
                                        name="epi_abnormalities_squamouscell_atypical_asch"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.epi_abnormalities_squamouscell_atypical_asch
                                        }
                                    />
                                    <Label for="41">
                                        cannont exclude HSIL (ASC-H)
                                    </Label>
                                </FormGroup>
                            </div>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    id="42"
                                    name="epi_abnormalities_squamouscell_lsil"
                                    onChange={this.handleChangeCheckbox}
                                    checked={
                                        formData.epi_abnormalities_squamouscell_lsil
                                    }
                                />
                                <Label for="42">
                                    Low grade Squamous Intraepithelial Lesion
                                    (LSIL encompassing):
                                </Label>
                            </FormGroup>
                            <div style={{ marginLeft: "50px" }}>
                                <FormGroup>
                                    <Input
                                        type="checkbox"
                                        id="43"
                                        name="epi_abnormalities_squamouscell_lsil_hpv"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.epi_abnormalities_squamouscell_lsil_hpv
                                        }
                                    />
                                    <Label for="43">HPV</Label>
                                    <br />
                                    <Input
                                        type="checkbox"
                                        id="44"
                                        name="epi_abnormalities_squamouscell_lsil_cin1"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.epi_abnormalities_squamouscell_lsil_cin1
                                        }
                                    />
                                    <Label for="44">Mild dysplasia/CIN 1</Label>
                                </FormGroup>
                            </div>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    id="45"
                                    name="epi_abnormalities_squamouscell_hsil"
                                    onChange={this.handleChangeCheckbox}
                                    checked={
                                        formData.epi_abnormalities_squamouscell_hsil
                                    }
                                />
                                <Label for="45">
                                    Hi grade Squamous Intraepithelial Lesion
                                    (HSIL) encompasing:
                                </Label>
                            </FormGroup>
                            <div style={{ marginLeft: "50px" }}>
                                <FormGroup>
                                    <Input
                                        type="checkbox"
                                        id="46"
                                        name="epi_abnormalities_squamouscell_hsil_cin2"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.epi_abnormalities_squamouscell_hsil_cin2
                                        }
                                    />
                                    <Label for="46">
                                        moderate dysplasia/CIN 2
                                    </Label>
                                    <br />
                                    <Input
                                        type="checkbox"
                                        id="47"
                                        name="epi_abnormalities_squamouscell_hsil_cin3"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.epi_abnormalities_squamouscell_hsil_cin3
                                        }
                                    />
                                    <Label for="47">
                                        severe dysplasia/CIN 3 & CIS
                                    </Label>
                                    <br />
                                    <Input
                                        type="checkbox"
                                        id="48"
                                        name="epi_abnormalities_squamouscell_hsil_withfeature"
                                        onChange={this.handleChangeCheckbox}
                                        checked={
                                            formData.epi_abnormalities_squamouscell_hsil_withfeature
                                        }
                                    />
                                    <Label for="48">
                                        with feature suspiscious for invasion
                                    </Label>
                                </FormGroup>
                            </div>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    id="49"
                                    name="epi_abnormalities_squamouscell_carcinoma"
                                    onChange={this.handleChangeCheckbox}
                                    checked={
                                        formData.epi_abnormalities_squamouscell_carcinoma
                                    }
                                />
                                <Label for="49">Squamous cell carcinoma</Label>
                            </FormGroup>
                        </div>
                        {/* Glandular cells */}
                        <FormGroup>
                            <Input
                                type="checkbox"
                                id="50"
                                name="epi_abnormalities_glandularcell"
                                onChange={this.handleChangeCheckbox}
                                checked={
                                    formData.epi_abnormalities_glandularcell
                                }
                            />
                            <Label for="50">
                                <strong>Glandular Cell</strong>
                            </Label>
                        </FormGroup>
                        <div style={{ marginLeft: "50px" }}>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Input
                                            type="checkbox"
                                            id="51"
                                            name="epi_abnormalities_glandularcell_atypical"
                                            onChange={this.handleChangeCheckbox}
                                            checked={
                                                formData.epi_abnormalities_glandularcell_atypical
                                            }
                                        />
                                        <Label for="51">Atypical</Label>
                                    </FormGroup>
                                    <div style={{ marginLeft: "50px" }}>
                                        <FormGroup>
                                            <Input
                                                type="checkbox"
                                                id="52"
                                                name="epi_abnormalities_glandularcell_atypical_endocervical"
                                                onChange={
                                                    this.handleChangeCheckbox
                                                }
                                                checked={
                                                    formData.epi_abnormalities_glandularcell_atypical_endocervical
                                                }
                                            />
                                            <Label for="52">
                                                Endocervical cells
                                            </Label>
                                            <br />
                                            <Input
                                                type="checkbox"
                                                id="53"
                                                name="epi_abnormalities_glandularcell_atypical_endometrial"
                                                onChange={
                                                    this.handleChangeCheckbox
                                                }
                                                checked={
                                                    formData.epi_abnormalities_glandularcell_atypical_endometrial
                                                }
                                            />
                                            <Label for="53">
                                                Endometrial cells
                                            </Label>
                                            <br />
                                            <Input
                                                type="checkbox"
                                                id="54"
                                                name="epi_abnormalities_glandularcell_atypical_situ"
                                                onChange={
                                                    this.handleChangeCheckbox
                                                }
                                                checked={
                                                    formData.epi_abnormalities_glandularcell_atypical_situ
                                                }
                                            />
                                            <Label for="54">
                                                Endocervical Adenocarcinoma in
                                                in situ
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    <FormGroup>
                                        <Input
                                            type="checkbox"
                                            id="55"
                                            name="epi_abnormalities_glandularcell_atypicalglandular"
                                            onChange={this.handleChangeCheckbox}
                                            checked={
                                                formData.epi_abnormalities_glandularcell_atypicalglandular
                                            }
                                        />
                                        <Label for="55">
                                            Atypical Glandular
                                        </Label>
                                    </FormGroup>
                                    <div style={{ marginLeft: "50px" }}>
                                        <FormGroup>
                                            <Input
                                                type="checkbox"
                                                id="56"
                                                name="epi_abnormalities_glandularcell_atypicalglandular_endocervical"
                                                onChange={
                                                    this.handleChangeCheckbox
                                                }
                                                checked={
                                                    formData.epi_abnormalities_glandularcell_atypicalglandular_endocervical
                                                }
                                            />
                                            <Label for="56">
                                                Endocervical Cells, Favor
                                                neoplastic
                                            </Label>
                                            <br />
                                            <Input
                                                type="checkbox"
                                                id="57"
                                                name="epi_abnormalities_glandularcell_atypicalglandular_glandular"
                                                onChange={
                                                    this.handleChangeCheckbox
                                                }
                                                checked={
                                                    formData.epi_abnormalities_glandularcell_atypicalglandular_glandular
                                                }
                                            />
                                            <Label for="57">
                                                Glandular cells
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    <FormGroup>
                                        <Input
                                            type="checkbox"
                                            id="58"
                                            name="epi_abnormalities_glandularcell_adenocarcinoma"
                                            onChange={this.handleChangeCheckbox}
                                            checked={
                                                formData.epi_abnormalities_glandularcell_adenocarcinoma
                                            }
                                        />
                                        <Label for="58">Adenocarcinoma</Label>
                                        <br />
                                    </FormGroup>
                                    <div style={{ marginLeft: "50px" }}>
                                        <FormGroup>
                                            <Input
                                                type="checkbox"
                                                id="59"
                                                name="epi_abnormalities_glandularcell_adenocarcinoma_endocervical"
                                                onChange={
                                                    this.handleChangeCheckbox
                                                }
                                                checked={
                                                    formData.epi_abnormalities_glandularcell_adenocarcinoma_endocervical
                                                }
                                            />
                                            <Label for="59">Endocervical</Label>
                                            <br />
                                            <Input
                                                type="checkbox"
                                                id="60"
                                                name="epi_abnormalities_glandularcell_adenocarcinoma_endometrial"
                                                onChange={
                                                    this.handleChangeCheckbox
                                                }
                                                checked={
                                                    formData.epi_abnormalities_glandularcell_adenocarcinoma_endometrial
                                                }
                                            />
                                            <Label for="60">Endometrial</Label>
                                            <br />
                                            <Input
                                                type="checkbox"
                                                id="61"
                                                name="epi_abnormalities_glandularcell_adenocarcinoma_extrauterine"
                                                onChange={
                                                    this.handleChangeCheckbox
                                                }
                                                checked={
                                                    formData.epi_abnormalities_glandularcell_adenocarcinoma_extrauterine
                                                }
                                            />
                                            <Label for="61">Extrauterine</Label>
                                            <br />
                                            <Input
                                                type="checkbox"
                                                id="62"
                                                name="epi_abnormalities_glandularcell_adenocarcinoma_notspecified"
                                                onChange={
                                                    this.handleChangeCheckbox
                                                }
                                                checked={
                                                    formData.epi_abnormalities_glandularcell_adenocarcinoma_notspecified
                                                }
                                            />
                                            <Label for="62">
                                                Not otherwise specified
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <FormGroup className="form-label-group">
                            <Input
                                type="text"
                                id="63"
                                placeholder="Organisms"
                                name="other_malignant_neoplasms"
                                onChange={this.handleChangeInput}
                                value={formData.other_malignant_neoplasms}
                            />
                            <Label for="63">Other Malignant Neoplasms</Label>
                        </FormGroup>
                    </div>
                    <hr />
                    <FormGroup className="">
                        <Label for="64">Notes and Suggesstions</Label>
                        <Input
                            type="textarea"
                            rows="4"
                            id="64"
                            placeholder="Notes and Suggesstions"
                            name="notes_and_suggestions"
                            onChange={this.handleChangeInput}
                            value={formData.notes_and_suggestions}
                        />
                    </FormGroup>
                    <hr />
                    {this.state.showAlert && (
                        <Alert fade={false} color="success">
                            <i className="fa fa-check" /> Record Successfully
                            Updated.
                        </Alert>
                    )}
                    <div className="text-center">
                        {editPapsmearRecordButton}{" "}
                        <Button
                            color="secondary"
                            onClick={() => {
                                let winPrint = window.open(
                                    "",
                                    "",
                                    "left=55,top=0,width=1200,height=600,toolbar=0,scrollbars=0,status=0"
                                );
                                winPrint.document.write(
                                    `<html><head><link rel="stylesheet"
                        type="text/css"
                        href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                        crossorigin="anonymous"/>
                        <style>
                            *{
                                list-style-type: none;
                                font-size: 17px;
                            }

                            hr{
                                border: 1px solid #0f0b30;
                            }

                            .header_divider {
                                border: 2px solid #0f0b30;
                            }
                        </style>
                        </head><body>` +
                                        document.getElementById(
                                            "cytopathologyPrintContent"
                                        ).innerHTML +
                                        `</body></html>`
                                );
                                winPrint.document.close();
                                setTimeout(() => {
                                    winPrint.focus();
                                    winPrint.print();
                                    winPrint.close();
                                }, 1000);
                            }}
                        >
                            Print
                        </Button>
                    </div>
                </Form>
                {/* --- */}
                <div
                    id="cytopathologyPrintContent"
                    className="container d-none"
                >
                    <FormPrintPapsmear patient={patient} formData={formData} />
                </div>
            </>
        );
    }
}
