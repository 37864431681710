import axios from "axios";
import { getToken } from "../auth/auth-utils/authHelper";

axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        alert(
            "An unexpected error occurred. Please try again.\nError: ",
            error
        );
    }

    return Promise.reject(error);
});

axios.defaults.headers.common["Authorization"] = getToken();

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    cancelToken: axios.CancelToken
};
