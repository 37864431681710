import React from "react";
import { Card, CardBody, Button, Table } from "reactstrap";

const PatientDetails = ({ patient, toggleModal }) => {
    return (
        <Card>
            <CardBody>
                Patient Details{" "}
                <Button
                    color="warning"
                    className="float-right"
                    size="sm"
                    title="Edit Patient Details"
                    onClick={() => toggleModal("modalEditPatient")}
                >
                    <i className="fa fa-edit" />{" "}
                </Button>
                <hr />
                <Table borderless size="sm">
                    <tbody>
                        <tr>
                            <td style={{ width: "140px" }}>
                                <strong>Family Name</strong>
                            </td>
                            <td>{patient.lastname}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>First Name</strong>
                            </td>
                            <td>{patient.firstname}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Middle Name</strong>
                            </td>
                            <td>{patient.middlename}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Age</strong>
                            </td>
                            <td>{patient.age}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Sex</strong>
                            </td>
                            <td>{patient.sex}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Address</strong>
                            </td>
                            <td>{patient.address}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Civil Status</strong>
                            </td>
                            <td>{patient.civil_status}</td>
                        </tr>
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default PatientDetails;
