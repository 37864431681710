import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//custom components
import Authenticating from "./Authenticating";

//ui components
import { Container, Col, FormGroup, Label, Input, Button } from "reactstrap";

import http from "./../services/httpService";
import { apiUrl } from "./../config.json";
import { getCurrentUser } from './auth-utils/authHelper'

const Login = props => {

    useEffect(() => {
        const user = getCurrentUser();        
        if(user){
            return props.history.push('/home')
        } 
    }, [])

    const [state, setState] = useState({
        email: "",
        password: "",
        loading: false,
        message: ""
    });

    function handleChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    function handleSubmit(e) {
        setState({ ...state, loading: true });
        e.preventDefault();

        const body = {
            email: state.email,
            password: state.password
        };

        http.post(apiUrl + "/auth/login", body)
            .then(response => {
                setTimeout(() => {
                    console.log(response.data.token);
                    
                    localStorage.setItem("token", response.data.token);

                    window.location = "/home";

                    return setState({
                        ...state,
                        loading: false
                    });
                }, 2000);
            })
            .catch(err => {
                console.log(err);
                return setState({
                    ...state,
                    loading: false,
                    message: "Wrong Credentials"
                });
            });
    }

    return (
        <div className="container-fluid pt-5" style={containerStyle}>
            <div className="col-5 mx-auto bg-white mt-5" style={loginStyle}>
                <Container className="p-3">
                    {state.loading ? (
                        <Authenticating />
                    ) : (
                        <Col className="mx-auto p-2">
                            <img
                                src="image/logo.png"
                                alt=""
                                className="img-fluid"
                            />
                            <h4 className="text-center">Sign in</h4>
                            <hr />
                            <form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="email"
                                        onChange={handleChange}
                                        value={state.email}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <Input
                                        type="password"
                                        name="password"
                                        id="password"
                                        onChange={handleChange}
                                        value={state.password}
                                    />
                                </FormGroup>

                                <Button
                                    color="primary"
                                    type="submit"
                                    size="lg"
                                    block
                                >
                                    Sign in
                                </Button>
                            </form>
                            <small>
                                Forgot password?
                                <Link to="/change"> change password.</Link>
                            </small>
                            {state.message ? (
                                <p className="text-danger h5 text-center">
                                    {state.message}
                                </p>
                            ) : null}                          
                        </Col>
                    )}
                </Container>
            </div>
        </div>
    );
};

const loginStyle = {
    borderRadius: "10px",
    width: "410px",
    boxShadow: "0px 9px 37px 3px rgba(0,0,0,0.19)"
};

const containerStyle = {
    backgroundImage: "url('image/background.png')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    height: "100vh"
};

export default Login;
