import React from "react";
import { Button, Table } from "reactstrap";

const Histopathology = ({ histopathology, toggleModal, action }) => {
    
    function convertTime(time) {
        var d = new Date(time);

        var date = d.getDate();
        var day = d.getDay();
        var monthIndex = d.getMonth();
        var year = d.getFullYear();

        var days = ["Sun.", "Mon.", "Tue.", "Wed.", "Thur.", "Fri.", "Sat."];
        var months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        return days[day] + " " + months[monthIndex] + " " + date + ", " + year;
    }

    const histopathologyRecord =
        histopathology.length === 0 ? (
            <tr>
                <td colSpan="3" className="text-center">
                    No histopathology record
                </td>
            </tr>
        ) : (
            histopathology.map(h => (
                <tr key={h.histopathology_id}>
                    <td>{convertTime(h.consultation_date)}</td>

                    <td className="text-nowrap">
                        <Button
                            title="Edit Consultation"
                            color="warning"
                            size="sm"
                            className="mr-1"
                            onClick={() =>
                                action(
                                    h.histopathology_id,
                                    "modalEditHistopathology"
                                )
                            }
                        >
                            <i className="fa fa-edit" />
                        </Button>
                        <Button
                            title="Delete Consultation"
                            color="danger"
                            size="sm"
                            className="mr-1"
                            onClick={() =>
                                action(
                                    h.histopathology_id,
                                    "modalDeleteHistopathology"
                                )
                            }
                        >
                            <i className="fa fa-trash" />
                        </Button>
                    </td>
                </tr>
            ))
        );

    return (
        <>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th
                            className="text-nowrap"
                            // style={{
                            //     width: "180px"
                            // }}
                        >
                            Consultation Date
                        </th>
                        {/* <th>Remarks</th> */}
                        <th
                            style={{
                                width: "100px"
                            }}
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>{histopathologyRecord}</tbody>
            </Table>
            <hr />
            <Button
                color="primary"
                className="mt-2"
                onClick={() => toggleModal("modalAddHistopathology")}
            >
                <i className="fa fa-plus" aria-hidden="true" /> New
                Histopathology Consultation
            </Button>
        </>
    );
};
export default Histopathology;
