import React, { Component } from "react";
import { Button } from "reactstrap";
import http from "../../services/httpService";
import { apiUrl } from "../../config.json";

export default class DeletePatientForm extends Component {
    state = {
        formLoading: false
    };

    //axios cancellation
    CancelToken = http.cancelToken;
    source = this.CancelToken.source();

    componentWillUnmount() {
        this.source.cancel("Operation deleting project cancelled!");
    }

    handleClick = () => {
        const { patient_id } = this.props.patient;

        this.setState(prevState => ({
            formLoading: !prevState.formLoading
        }));

        http
            .delete(apiUrl + `/patient/${patient_id}`, {
                cancelToken: this.source.token
            })
            .then(response => {
                if (response.status === 200 && response.statusText === "OK") {
                    this.props.deleteProject(patient_id);
                }
            })
            .catch(error => {
                alert(error);
            });
    };

    render() {
        const deleteProjectButton = this.state.formLoading ? (
            <Button disabled color="danger">
                Deleting ...
            </Button>
        ) : (
            <Button color="danger" onClick={this.handleClick}>
                <i className="fa fa-trash" aria-hidden="true" /> Delete
            </Button>
        );

        const { lastname, firstname, middlename } = this.props.patient;

        return (
            <div className="text-center">
                <p>
                    Are you sure do you want to delete Patient{" "}
                    <u>{lastname + " " + firstname + " " + middlename}</u> ?
                </p>
                {deleteProjectButton}
            </div>
        );
    }
}
