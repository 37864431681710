import React, { Component } from "react";
import { Form, FormGroup, FormText, Label, Input, Button } from "reactstrap";

import http from "../../services/httpService";
import { apiUrl } from "../../config.json";

export default class FormEditTemplate extends Component {
    state = {
        formLoading: false,
        formData: {
            template_id: "",
            template_name: "",
            search_keys: "",
            diagnosis: "",
            examination: ""
        },
        formErrors: {
            template_name: "",
            search_keys: "",
            diagnosis: "",
            examination: ""
        }
    };

    //axios cancellation
    CancelToken = http.cancelToken;
    source = this.CancelToken.source();

    componentWillUnmount() {
        this.source.cancel("Operation updating patient cancelled!");
    }

    componentDidMount() {
        this.setState({
            formData: this.props.template
        });
    }

    handleChange = e => {
        let formData = { ...this.state.formData };
        formData[e.target.name] = e.target.value;
        this.setState({ formData });
    };

    handleBlur = e => {
        let formErrors = { ...this.state.formErrors };
        if (e.target.value.trim() !== "") {
            formErrors[e.target.name] = "";
        }
        this.setState({ formErrors });
    };

    validateInput = () => {
        const { template_name, search_keys } = this.state.formData;

        let errors = {};
        if (template_name.trim() === "") {
            errors.template_name = "Please enter template name";
        }
        if (search_keys.trim() === "") {
            errors.search_keys = "Please search key";
        }
        // if (diagnosis.trim() === "") {
        //     errors.diagnosis = "Please enter diagnosis";
        // }
        // if (examination.trim() === "") {
        //     errors.examination = "Please enter examination";
        // }
        return Object.keys(errors).length === 0 ? null : errors;
    };

    handleSubmit = e => {
        e.preventDefault();
        const formErrors = this.validateInput();
        if (formErrors) {
            this.setState({
                formErrors
            });
        } else {
            this.setState(prevState => ({
                formLoading: !prevState.formLoading
            }));

            let formData = { ...this.state.formData };
            const { template_id } = formData;
            delete formData.template_id;

            http.put(
                apiUrl + `/template/${template_id}`,
                { ...this.state.formData },
                {
                    cancelToken: this.source.token
                }
            )
                .then(response => {
                    this.props.editTemplate({ ...this.state.formData });
                    this.props.showEditTemplateForm();
                })
                .catch(error => {
                    alert(error);
                });
        }
    };

    render() {
        const { formData, formErrors, formLoading } = this.state;

        const editTemplateButton = formLoading ? (
            <Button type="submit" disabled color="warning">
                Updating ...
            </Button>
        ) : (
            <Button type="submit" color="warning">
                <i className="fa fa-save" /> Update Template
            </Button>
        );

        return (
            <>
                <h5>Edit Template</h5>
                <Form className="papsmear-form" onSubmit={this.handleSubmit}>
                    <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            id="txtTemplateName"
                            name="template_name"
                            placeholder="Template Name"
                            value={formData.template_name}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            invalid={formErrors.template_name ? true : false}
                        />
                        <Label for="txtTemplateName">Template Name</Label>
                        <FormText>{formErrors.template_name}</FormText>
                    </FormGroup>
                    <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            id="txtSearchKey"
                            name="search_keys"
                            placeholder="Search Key"
                            value={formData.search_keys}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            invalid={formErrors.search_keys ? true : false}
                        />
                        <Label for="txtSearchKey">Search Key</Label>
                        <FormText>{formErrors.search_keys}</FormText>
                    </FormGroup>
                    <hr />
                    <FormGroup>
                        <Label for="txtFinalDiagnosis">Final Diagnosis:</Label>
                        <Input
                            type="textarea"
                            id="txtFinalDiagnosis"
                            name="diagnosis"
                            placeholder="Final Diagnosis"
                            rows="3"
                            value={formData.diagnosis}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            invalid={formErrors.diagnosis ? true : false}
                        />
                        <FormText>{formErrors.diagnosis}</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="txtMacroMicroExamination">
                            Macroscopic and
                            <br />
                            Microscopic Examination:
                        </Label>
                        <Input
                            type="textarea"
                            id="txtMacroMicroExamination"
                            name="examination"
                            placeholder="Macro and Micro Examination"
                            rows="3"
                            value={formData.examination}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            invalid={formErrors.examination ? true : false}
                        />
                        <FormText>{formErrors.examination}</FormText>
                    </FormGroup>
                    {editTemplateButton}
                    <Button
                        className="ml-1"
                        color="secondary"
                        onClick={() => this.props.showEditTemplateForm(0)}
                    >
                        Cancel
                    </Button>
                </Form>
            </>
        );
    }
}
