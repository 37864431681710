import jwtDecode from "jwt-decode";

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem("token");
        const currentUser = jwtDecode(jwt);
        return currentUser;
    } catch (err) {
        return null;
    }
}

export function getToken() {
    return localStorage.getItem("token");
}

export function logOut() {
    localStorage.removeItem("token");
    window.location = "/";
}
