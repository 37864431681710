import React, { Component } from "react";
import { Button } from "reactstrap";
import http from "../../services/httpService";
import { apiUrl } from "../../config.json";

export default class DeletePapsmearForm extends Component {
    state = {
        formLoading: false
    };

    //axios cancellation
    CancelToken = http.cancelToken;
    source = this.CancelToken.source();

    componentWillUnmount() {
        this.source.cancel("Operation deleting papsmear cancelled!");
    }

    handleClick = () => {
        const { cytopathology_id } = this.props.papsmear;

        this.setState(prevState => ({
            formLoading: !prevState.formLoading
        }));

        http
            .delete(apiUrl + `/papsmear/${cytopathology_id}`, {
                cancelToken: this.source.token
            })
            .then(response => {
                if (response.status === 200 && response.statusText === "OK") {
                    this.props.deletePapsmear(cytopathology_id);
                }
            })
            .catch(error => {
                alert(error);
            });
    };

    render() {
        const deletePapsmearButton = this.state.formLoading ? (
            <Button disabled color="danger">
                Deleting ...
            </Button>
        ) : (
            <Button color="danger" onClick={this.handleClick}>
                <i className="fa fa-trash" aria-hidden="true" /> Delete
            </Button>
        );

        return (
            <div className="text-center">
                <p>
                    Are you sure do you want to delete this consultation record?
                </p>
                {deletePapsmearButton}
            </div>
        );
    }
}
