import React from "react";
import Celso_Sign from "../../img/celso_sign.png";

const FormPrintOthers = ({ patient, inputs }) => {
    return (
        <div className="col-12 main">
            <div className="text-center othersHeader">
                <p className="h2 primary font-weight-bold mb-0">
                    {" "}
                    CELSO S. RAMOS, M.D., F.P.S.P{" "}
                </p>
                <p className=" primary">
                    Diplomate, Phil Board of Pathology (Anatomic & Clinical)
                    <br /> Fellow, Phil, Society of the Pathologists.
                </p>
            </div>

            <div className="othersBody">
                <hr className="header_divider" />

                <div className="text-center">
                    <p className="mb-0 primary">
                        SURGICAL PATHOLOGY CONSULTATION REPORT
                    </p>
                </div>

                <hr />

                <div className="row mb-4">
                    <div className="col-6">
                        <li className="mb-1">
                            <span className="primary">Patient:</span>{" "}
                            <span>
                                {`${patient.lastname}, ${patient.firstname} ${
                                    patient.middlename
                                    }`}
                            </span>
                        </li>

                        <li className="mb-1">
                            <span className="primary">Date:</span>{" "}
                            <span>{new Date().toDateString()}</span>
                        </li>
                    </div>

                    <div className="col-6">
                        <li className="mb-1">
                            <span className="primary">Age:</span>{" "}
                            <span>{patient.age === 0 ? "" : patient.age}</span>
                        </li>

                        <li className="mb-1">
                            <span className="primary">Sex:</span>{" "}
                            <span>{`${patient.sex}`}</span>
                        </li>

                        <li className="mb-1">
                            <span className="primary">Referred by:</span>
                            <span>{inputs.referred_by}</span>
                        </li>
                    </div>
                </div>

                <div className="border p-2">
                    <p className="primary font-weight-bold">PROCEDURE</p>
                    <span>{inputs.procedure.split("\n")
                        .map((item, key) => {
                            return (
                                <span key={key}>
                                    {item}
                                    <br />
                                </span>
                            );
                        })}</span>
                    <hr />
                    <p className="primary font-weight-bold">RESULTS</p>
                    <span>{inputs.result.split("\n")
                        .map((item, key) => {
                            return (
                                <span key={key}>
                                    {item}
                                    <br />
                                </span>
                            );
                        })}</span>
                </div>

                <div className="footer">
                    <div className="row">
                        <div className="col-7" />
                        <div className="col-5">
                            <div className="doctorSignature">
                                <img
                                    src={Celso_Sign}
                                    className="img-fluid"
                                    width="50%"
                                    alt="sign"
                                    style={{
                                        marginBottom: "-64px",
                                        marginLeft: "70px"
                                    }}
                                />
                                <p className="primary ">
                                    CELSO S. RAMOS M.D, FPSP, MBAH
                                </p>
                                PRC License no: 46296
                                <br />
                                Anatomic and Clinical Pathologist
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormPrintOthers;
