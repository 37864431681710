import React, { Component } from "react";
import { Button } from "reactstrap";
import http from "../../services/httpService";
import { apiUrl } from "../../config.json";

export default class DeleteHistopathologyForm extends Component {
    state = {
        formLoading: false
    };

    //axios cancellation
    CancelToken = http.cancelToken;
    source = this.CancelToken.source();

    componentWillUnmount() {
        this.source.cancel("Operation deleting histopathology cancelled!");
    }

    handleClick = () => {
        const { histopathology_id } = this.props.histopathology;

        this.setState(prevState => ({
            formLoading: !prevState.formLoading
        }));

        http
            .delete(apiUrl + `/surgical/${histopathology_id}`, {
                cancelToken: this.source.token
            })
            .then(response => {
                if (response.status === 200 && response.statusText === "OK") {
                    this.props.deleteHistopathology(histopathology_id);
                }
            })
            .catch(error => {
                alert(error);
            });
    };

    render() {
        const deleteHistopathologyButton = this.state.formLoading ? (
            <Button disabled color="danger">
                Deleting ...
            </Button>
        ) : (
            <Button color="danger" onClick={this.handleClick}>
                <i className="fa fa-trash" aria-hidden="true" /> Delete
            </Button>
        );

        return (
            <div className="text-center">
                <p>
                    Are you sure do you want to delete this consultation record?
                </p>
                {deleteHistopathologyButton}
            </div>
        );
    }
}
