import React from "react";
import Celso_Sign from "../../img/celso_sign.png";

const FormPrintPapsmear = ({ formData, patient }) => {
    function convertToWholeDate(datetime) {
        if (datetime) {
            let d = new Date(datetime);

            let date = d.getDate();
            let monthIndex = d.getMonth();
            let year = d.getFullYear();

            let months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ];
            return months[monthIndex] + " " + date + ", " + year;
        } else {
            return "-";
        }
    }

    return (
        <div className="col-12 main">
            <div className="papsmearHeader">
                <div className="text-center">
                    <p className="h2 font-weight-bold mb-0">
                        CELSO S. RAMOS, M.D., F.P.S.P
                    </p>
                    <p className="">
                        Diplomate, Phil Board of Pathology (Anatomic & Clinical)
                        <br />
                        Fellow, Phil, Society of the Pathologists.
                    </p>
                </div>
            </div>

            <hr className="header_divider" />

            <div className="text-center">
                <p className="h6 mb-0">
                    CYTOPATHOLOGY CONSULTATION REPORT (THE BETHESDA SYSTEM 2014)
                </p>
            </div>

            <hr />

            <div className="row">
                <div className="col-4">
                    <span className="h6 mr-2">Name:</span>
                    <span>{patient.name}</span>
                </div>

                <div className="col-4">
                    <span className="h6 mr-2">Physician:</span>
                    <span>{formData.physician}</span>
                </div>

                <div className="col-4">
                    <span className="h6 mr-2">Date:</span>
                    <span>
                        {convertToWholeDate(formData.consultation_date)}
                    </span>
                </div>
            </div>

            <div className="row mt-1">
                <div className="col-4">
                    <span className="h6 mr-2">Age:</span>
                    <span>{patient.age === 0 ? "" : patient.age}</span>
                </div>

                <div className="col-4">
                    <span className="h6 mr-2">AC no:</span>
                    <span>{formData.ac_no}</span>
                </div>

                <div className="col-4">
                    <span className="h6 mr-2">Hospital:</span>
                    <span>{formData.hospital}</span>
                </div>
            </div>

            <hr />

            <ul className="list-inline mb-0">
                <li className="list-inline-item mr-5">
                    <p className="h6">Civil Status:</p>
                </li>
                <li className="list-inline-item mr-5">
                    <p>
                        ({patient.civilStatus === "Married" ? "✓" : " "})
                        Married
                    </p>
                </li>
                <li className="list-inline-item mr-5">
                    <p>
                        ({patient.civilStatus === "Single" ? "✓" : " "}) Single
                    </p>
                </li>
                <li className="list-inline-item mr-5">
                    <p>({patient.civilStatus === "Widow" ? "✓" : " "}) Widow</p>
                </li>
            </ul>

            <ul className="list-inline mb-0">
                <li className="list-inline-item mr-5">
                    <span className="h6">Speciment/s:</span>
                    <span>{formData.specimen}</span>
                </li>
                <li className="list-inline-item mr-5">
                    <span>
                        ({formData.specimen_cervical_smear ? "✓" : " "})
                        Cervical Smear
                    </span>
                </li>
                <li className="list-inline-item mr-5">
                    <span>
                        ({formData.specimen_vaginal_smear ? "✓" : " "}) Vaginal
                        Smear
                    </span>
                </li>
                <li className="list-inline-item mr-5">
                    <span>({formData.specimen_others ? "✓" : " "}) Others</span>
                </li>
            </ul>

            <span className="h6">Pertinent Data: </span>
            <span>{formData.pertinent_data}</span>

            <hr />

            <ul className="list-inline mb-0">
                <li className="list-inline-item mr-5">
                    <span className="h6 mr-5">Specimen Adequacy:</span>
                </li>

                <li className="list-inline-item mr-5">
                    <span>
                        (
                        {formData.specimen_adequacy === "satisfactory"
                            ? "✓"
                            : " "}
                        ) Satisfactory
                    </span>
                </li>
                <li className="list-inline-item mr-5">
                    <span>
                        (
                        {formData.specimen_adequecy === "unsatisfactory"
                            ? "✓"
                            : " "}
                        ) Unsatisfactory
                    </span>
                </li>
            </ul>

            <div className="row">
                <div className="col-6">
                    <p className="primary h6">General Categorization:</p>

                    <ul className="mb-2">
                        <li>
                            (
                            {formData.general_categorization_negative
                                ? "✓"
                                : " "}
                            ) Negative for Intraepithelial Lesion or Malignancy
                        </li>

                        <li>
                            (
                            {formData.general_categorization_epithelial
                                ? "✓"
                                : " "}
                            ) Epithelial Cell Abnormality [see
                            interpretation/diagnosis]
                        </li>

                        <li>
                            (
                            {formData.general_categorization_others ? "✓" : " "}
                            ) Others [see interpretation/diagnosis]
                        </li>
                    </ul>
                </div>

                <div className="col-6">
                    <p className="primary mb-0 h6">Date Requested:</p>
                    <span>
                        {convertToWholeDate(formData.consultation_date)}
                    </span>
                </div>
            </div>

            <span className="h6 mr-1">Automated Review:</span>
            <span>{formData.automated_review}</span>

            <hr />

            <p className="h6">Descriptive Interpretation Diagnosis:</p>

            <ul className="pl-5 list-inline mb-0">
                <li className="list-inline-item mr-5">
                    <p className="primary h6">
                        ({formData.diagnosis_non_neoplastic ? "✓" : " "})
                        Non-Neoplastic
                    </p>
                </li>

                <li className="list-inline-item">
                    ({formData.diagnosis_negative_intraepithelial ? "✓" : " "})
                    Negative for Intraepithelial Lesion or Malignancy
                </li>
            </ul>

            <ul className="list-inline pl-5">
                <li className="list-inline-item mr-5">
                    <p className="h6">
                        Organisms: <span>{formData.organisms}</span>
                    </p>
                </li>
                <li className="list-inline-item mr-5">
                    ({formData.organisms_count === "Few" ? "✓" : " "}) Few
                </li>
                <li className="list-inline-item mr-5">
                    ({formData.organisms_count === "Minimal" ? "✓" : " "})
                    Minimal
                </li>
                <li className="list-inline-item mr-5">
                    ({formData.organisms_count === "Moderate" ? "✓" : " "})
                    Moderate
                </li>
                <li className="list-inline-item mr-5">
                    ({formData.organisms_count === "Many" ? "✓" : " "}) Many
                </li>
            </ul>

            <p className="h6 m-1 pl-3">Non-Neoplastic Findings:</p>

            <ul className="list-inline pl-5 mb-2">
                <li className="list-inline-item mr-5">
                    ({formData.non_neoplastic_findings_reactive ? "✓" : " "})
                    Reative cellular changes associated with:
                    <span>
                        {formData.non_neoplastic_findings_reactive_assoc_with}
                    </span>
                </li>
                <li className="list-inline-item mr-5">
                    ({formData.non_neoplastic_findings_inflammation ? "✓" : " "}
                    ) Inflammation:
                    <span>{formData.inflammation}</span>
                </li>
                <li className="list-inline-item mr-5">
                    ({formData.non_neoplastic_findings_radiation ? "✓" : " "})
                    Radiation
                </li>
                <li className="list-inline-item mr-5">
                    ({formData.non_neoplastic_findings_uid ? "✓" : " "}) IUD
                </li>
            </ul>

            <ul className="list-inline pl-5 mb-2">
                <li className="list-inline-item mr-5">
                    (
                    {formData.non_neoplastic_findings_other_nonneoplastic
                        ? "✓"
                        : " "}
                    ) Other Non-neoplastic Findings:
                </li>
                <li className="list-inline-item mr-5">
                    ({formData.non_neoplastic_findings_atrophy ? "✓" : " "})
                    Atrophy
                </li>
            </ul>

            <p className="pl-5 mb-4">
                ({formData.non_neoplastic_findings_others ? "✓" : " "}) Others:
                Endometrial cells (in women more that 40 years of age)
            </p>

            <p className="primary h6 pl-4 mb-2">
                ({formData.epi_abnormalities ? "✓" : " "}) Epithelial Cell
                Abnormalities
            </p>

            <p className="h6 pl-5 mb-2">
                ({formData.epi_abnormalities_squamouscell ? "✓" : " "}) Squamous
                Cell
            </p>

            <div className="pl-5">
                <p className="h6 pl-4">
                    (
                    {formData.epi_abnormalities_squamouscell_atypical
                        ? "✓"
                        : " "}
                    ) Atypical Squamous Cells:
                </p>
                <div className="d-flex pl-5 justify-content-between col-10">
                    <p>
                        (
                        {formData.epi_abnormalities_squamouscell_atypical_ascus
                            ? "✓"
                            : " "}
                        ) Of undetermined Significance (AS-CUS)
                    </p>
                    <p>
                        (
                        {formData.epi_abnormalities_squamouscell_atypical_asch
                            ? "✓"
                            : " "}
                        ) Cannot exclude HSIL (ASC-H)
                    </p>
                </div>

                <p className="h6 pl-4">
                    ({formData.epi_abnormalities_squamouscell_lsil ? "✓" : " "})
                    Low-grade Squamous Intraepithelial Lesion (LSIL)
                    encompassing:
                </p>
                <div className="d-flex pl-5 justify-content-between col-9">
                    <p>
                        (
                        {formData.epi_abnormalities_squamouscell_lsil_hpv
                            ? "✓"
                            : " "}
                        ) HPV
                    </p>
                    <p>
                        (
                        {formData.epi_abnormalities_squamouscell_lsil_cin1
                            ? "✓"
                            : " "}
                        ) Mild Dysplasia/CIN 1
                    </p>
                </div>

                <p className="h6 pl-4">
                    ({formData.epi_abnormalities_squamouscell_hsil ? "✓" : " "})
                    High-grade Squamous Intraepithelial Lesion (HSIL)
                    encompassing:
                </p>
                <div className="d-flex pl-5 justify-content-between col-12">
                    <p>
                        (
                        {formData.epi_abnormalities_squamouscell_hsil_cin2
                            ? "✓"
                            : " "}
                        ) Moderate dysplacia/CIN2
                    </p>
                    <p>
                        (
                        {formData.epi_abnormalities_squamouscell_hsil_cin3
                            ? "✓"
                            : " "}
                        ) Severe dysplasia/CIN3 & CIS
                    </p>
                    <p>
                        (
                        {formData.epi_abnormalities_squamouscell_hsil_withfeature
                            ? "✓"
                            : " "}
                        ) With feature suspicius for invasion
                    </p>
                </div>
            </div>

            <p className="primary h6 pl-5 mb-2">
                ({formData.epi_abnormalities_glandularcell ? "✓" : " "})
                Glandular Cell
            </p>

            <ul className="list-inline mb-1 pl-5 ml-4">
                <li className="list-inline-item">
                    <p className="primary h6">
                        (
                        {formData.epi_abnormalities_glandularcell_atypical
                            ? "✓"
                            : " "}
                        ) Atypical:
                    </p>
                </li>

                <li className="list-inline-item">
                    (
                    {formData.epi_abnormalities_glandularcell_atypical_endocervical
                        ? "✓"
                        : " "}
                    ) Endocervical cells
                </li>

                <li className="list-inline-item">
                    (
                    {formData.epi_abnormalities_glandularcell_atypical_endometrial
                        ? "✓"
                        : " "}
                    ) Endometrial Cells
                </li>

                <li className="list-inline-item">
                    (
                    {formData.epi_abnormalities_glandularcell_atypical_situ
                        ? "✓"
                        : " "}
                    ) Endocervical Adenocarcinoma in situ
                </li>
            </ul>

            <ul className="list-inline pl-5 ml-4 mb-1">
                <li className="list-inline-item">
                    (
                    {formData.epi_abnormalities_glandularcell_atypicalglandular
                        ? "✓"
                        : " "}
                    ) Atypical Glandular
                </li>

                <li className="list-inline-item">
                    (
                    {formData.epi_abnormalities_glandularcell_atypicalglandular_endocervical
                        ? "✓"
                        : " "}
                    ) Endocervical cells, Favor neoplastic
                </li>

                <li className="list-inline-item">
                    (
                    {formData.epi_abnormalities_glandularcell_atypicalglandular_glandular
                        ? "✓"
                        : " "}
                    ) Glandular Cells
                </li>
            </ul>

            <ul className="list-inline pl-5 ml-4">
                <li className="list-inline-item">
                    <p className="primary h6">
                        (
                        {formData.epi_abnormalities_glandularcell_adenocarcinoma
                            ? "✓"
                            : " "}
                        ) Adenocarcinoma:
                    </p>
                </li>

                <li className="list-inline-item">
                    (
                    {formData.epi_abnormalities_glandularcell_adenocarcinoma_endocervical
                        ? "✓"
                        : " "}
                    ) Endocervical
                </li>

                <li className="list-inline-item">
                    (
                    {formData.epi_abnormalities_glandularcell_adenocarcinoma_endometrial
                        ? "✓"
                        : " "}
                    ) Endometrial
                </li>

                <li className="list-inline-item">
                    (
                    {formData.epi_abnormalities_glandularcell_adenocarcinoma_extrauterine
                        ? "✓"
                        : " "}
                    ) Extrauterine
                </li>

                <li className="list-inline-item">
                    (
                    {formData.epi_abnormalities_glandularcell_adenocarcinoma_notspecified
                        ? "✓"
                        : " "}
                    ) Not otherwise specified
                </li>
            </ul>

            <span className="primary h6 mr-1 pl-5">
                ( ) Other Malignant Neoplasms:
            </span>
            <span>{formData.other_malignant_neoplasms}</span>

            <hr />

            <div className="row">
                <div className="col-8">
                    <p className="primary h6">Notes and Suggestions:</p>
                    <span>
                        {formData.notes_and_suggestions
                            .split("\n")
                            .map((item, key) => {
                                return (
                                    <span key={key}>
                                        {item}
                                        <br />
                                    </span>
                                );
                            })}
                    </span>
                </div>

                <div className="col-4">
                    <img
                        src={Celso_Sign}
                        className="img-fluid ml-4"
                        width="50%"
                        alt="sign"
                        style={{ marginBottom: "-45px" }}
                    />
                    <p className="primary h6 text-left ">
                        CELSO S. RAMOS M.D, FPSP, MBAH
                    </p>
                    PRC License no: 46296
                    <br />
                    Anatomic and Clinical Pathologist
                </div>
            </div>
        </div>
    );
};

export default FormPrintPapsmear;
