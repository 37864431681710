import React, { Component } from "react";
import { Link } from "react-router-dom";
import Main from "./Main";
import FormAddPatient from "./forms/FormAddPatient";
import FormEditPatient from "./forms/FormEditPatient";
import FormDeletePatient from "./forms/FormDeletePatient";
import ButtonBottomRight from "./ButtonBottomRight";
import {
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    Table,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

import { getCurrentUser } from "./../auth/auth-utils/authHelper";

import http from "./../services/httpService";
import { apiUrl } from "./../config.json";

export default class Home extends Component {
    state = {
        isLoading: false,
        queryString: "",
        searchBtnDisabled: false,
        searchBtnText: "Search Patient",
        searchResult: [],
        searchBy: "name",
        customMessage: "",

        modalAddPatient: false,
        modalEditPatient: false,
        modalDeletePatient: false,
        focusAfterClose: false,
        selectedPatient: {}
    };

    componentDidMount() {
        const user = getCurrentUser();
        if (!user) {
            return this.props.history.push("/login");
        }
    }

    searchPatient = e => {
        e.preventDefault();

        if (this.state.queryString.trim()) {
            //type atlease 3 letters
            if (this.state.queryString.length > 2) {
                this.setState({
                    searchBtnDisabled: true,
                    searchBtnText: "Searching...",
                    searchResult: "loading"
                });

                http.get(apiUrl + "/search?term=" + this.state.queryString)
                    .then(response => {
                        if (
                            response.status === 200 &&
                            response.statusText === "OK"
                        ) {
                            if (response.data.patient.length === 0) {
                                this.setState({
                                    searchBtnDisabled: false,
                                    searchBtnText: "Search Patient",
                                    searchResult: "No patient found"
                                });
                            } else {
                                this.setState({
                                    searchBtnDisabled: false,
                                    searchBtnText: "Search Patient",
                                    searchResult: response.data.patient,
                                    isLoading: false
                                });
                            }
                        }
                    })
                    .catch(errors => {
                        alert(errors);
                    });
            } else {
                alert("Please type atlease 3 letters.");
            }
        }
    };

    editPatient = editedPatient => {
        const oldSearchResult = [...this.state.searchResult];
        const searchResult = oldSearchResult.map(patient => {
            if (patient.patient_id === editedPatient.patient_id) {
                patient.lastname = editedPatient.lastname;
                patient.firstname = editedPatient.firstname;
                patient.middlename = editedPatient.middlename;
                patient.address = editedPatient.address;
                patient.age = editedPatient.age;
                patient.sex = editedPatient.sex;
                patient.civil_status = editedPatient.civil_status;

                return patient;
            }

            return patient;
        });

        this.setState({ searchResult });
    };

    deletePatient = patient_id => {
        const searchResult = this.state.searchResult.filter(patient => {
            return patient.patient_id !== patient_id;
        });
        this.setState({
            searchResult,
            modalDeletePatient: false
        });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    setSelectedPatient = (id, modal) => {
        const selectedPatient = this.state.searchResult.filter(patient => {
            return patient.patient_id === id;
        });

        this.setState(prevState => ({
            selectedPatient: selectedPatient[0],
            [modal]: !prevState[modal]
        }));
    };

    toggleModal = modal => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    };

    searchByAc_no = async e => {
        e.preventDefault();

        if (this.state.queryString.trim()) {
            this.setState({
                searchBtnDisabled: true,
                searchBtnText: "Searching...",
                searchResult: "loading"
            });

            const response = await http.get(
                `${apiUrl}/acno/?term=${this.state.queryString}`
            );

            const searchResult = response.data.result.map(histopath => {
                return histopath.patient;
            });

            if (searchResult.length === 0) {
                this.setState({
                    searchBtnDisabled: false,
                    searchBtnText: "Search Patient",
                    searchResult: "No records found"
                });
            } else {
                this.setState({
                    searchBtnDisabled: false,
                    searchBtnText: "Search Patient",
                    searchResult,
                    isLoading: false
                });
            }
        }
    };

    render() {
        let { searchResult } = this.state;

        let searchResultContent = null;

        if (typeof searchResult === "string") {
            if (searchResult === "loading") {
                searchResultContent = (
                    <tr>
                        <td colSpan="6" className="text-center">
                            <Spinner size="sm" color="primary" />
                            <br />
                            Searching...
                        </td>
                    </tr>
                );
            } else {
                searchResultContent = (
                    <tr>
                        <td colSpan="6" className="text-center">
                            {searchResult}
                        </td>
                    </tr>
                );
            }
        } else if (typeof searchResult === "object") {
            searchResultContent = this.state.searchResult.map(
                (patient, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{patient.lastname}</td>
                            <td>{patient.firstname}</td>
                            <td>{patient.middlename}</td>
                            <td>{patient.address}</td>
                            <td className="text-nowrap">
                                <Link to={`/record/${patient.patient_id}`}>
                                    <Button
                                        title="View Patient Record"
                                        color="primary"
                                        size="sm"
                                        className="mr-1"
                                    >
                                        View Record
                                    </Button>
                                </Link>
                                <Button
                                    title="Edit Patient"
                                    color="warning"
                                    size="sm"
                                    className="mr-1"
                                    onClick={() =>
                                        this.setSelectedPatient(
                                            patient.patient_id,
                                            "modalEditPatient"
                                        )
                                    }
                                >
                                    <i
                                        className="fa fa-edit"
                                        aria-hidden="true"
                                    />
                                </Button>
                                <Button
                                    color="danger"
                                    size="sm"
                                    title="Delete Patient"
                                    onClick={() =>
                                        this.setSelectedPatient(
                                            patient.patient_id,
                                            "modalDeletePatient"
                                        )
                                    }
                                >
                                    <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                    />
                                </Button>
                            </td>
                        </tr>
                    );
                }
            );
        }

        return (
            <>
                <Main>
                    {/* added this */}
                    <div className="d-flex justify-content-between">
                        <h3 className="h3">Patient</h3>
                        <h6 className="mt-2">
                            Result:{" "}
                            <span className="text-primary">
                                {typeof searchResult === "string"
                                    ? 0
                                    : this.state.searchResult.length}{" "}
                                records found.
                            </span>
                        </h6>
                    </div>
                    {/* added this */}

                    <hr />

                    <Form
                        onSubmit={
                            this.state.searchBy === "name"
                                ? this.searchPatient
                                : this.searchByAc_no
                        }
                    >
                        <FormGroup>
                            <InputGroup>
                                <div
                                    className="m-0 pr-4"
                                    style={{ margin: "15px" }}
                                >
                                    <Input
                                        onChange={this.handleChange}
                                        type="select"
                                        name="searchBy"
                                        bsSize="lg"
                                        id="txtPatientSex"
                                        autoComplete="off"
                                        value={this.state.searchBy}
                                    >
                                        <option value="name">
                                            Seach by name
                                        </option>
                                        <option value="ac_no">
                                            Search by Ac_No.
                                        </option>
                                    </Input>
                                </div>

                                <Input
                                    type="text"
                                    name="queryString"
                                    placeholder="Search"
                                    bsSize="lg"
                                    autoComplete="off"
                                    onChange={this.handleChange}
                                />
                                <InputGroupAddon addonType="append">
                                    <Button
                                        color="secondary"
                                        disabled={this.state.searchBtnDisabled}
                                    >
                                        <i
                                            className="fa fa-search"
                                            aria-hidden="true"
                                        />{" "}
                                        {this.state.searchBtnText}
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Family Name</th>
                                <th>First Name</th>
                                <th>Middle</th>
                                <th>Address</th>
                                <th style={{ width: "191px" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>{searchResultContent}</tbody>
                    </Table>
                </Main>
                <ButtonBottomRight
                    tooltipText="Add Patient"
                    onclick={() => this.toggleModal("modalAddPatient")}
                />

                {/* Modal Create Patient */}
                <Modal
                    isOpen={this.state.modalAddPatient}
                    fade={false}
                    toggle={() => this.toggleModal("modalAddPatient")}
                    returnFocusAfterClose={this.state.focusAfterClose}
                >
                    <ModalHeader
                        toggle={() => this.toggleModal("modalAddPatient")}
                    >
                        Add Patient
                    </ModalHeader>
                    <ModalBody>
                        <FormAddPatient
                            setLatestPatientCreated={
                                this.props.setLatestPatientCreated
                            }
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => this.toggleModal("modalAddPatient")}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Modal Edit Patient */}
                <Modal
                    isOpen={this.state.modalEditPatient}
                    fade={false}
                    toggle={() => this.toggleModal("modalEditPatient")}
                    returnFocusAfterClose={this.state.focusAfterClose}
                >
                    <ModalHeader
                        toggle={() => this.toggleModal("modalEditPatient")}
                    >
                        Edit Patient
                    </ModalHeader>
                    <ModalBody>
                        <FormEditPatient
                            patient={this.state.selectedPatient}
                            editPatient={this.editPatient}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => this.toggleModal("modalEditPatient")}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Modal Delete Patient */}
                <Modal
                    isOpen={this.state.modalDeletePatient}
                    fade={false}
                    toggle={() => this.toggleModal("modalDeletePatient")}
                    returnFocusAfterClose={this.state.focusAfterClose}
                >
                    <ModalHeader
                        toggle={() => this.toggleModal("modalDeletePatient")}
                    >
                        Delete Patient
                    </ModalHeader>
                    <ModalBody>
                        <FormDeletePatient
                            patient={this.state.selectedPatient}
                            deleteProject={this.deletePatient}
                        />
                    </ModalBody>
                </Modal>
            </>
        );
    }
}
