import React from "react";
import { Button, Table } from "reactstrap";
const PapsmearData = ({ papsmear, toggleModal, action }) => {
    function convertTime(time) {
        var d = new Date(time);

        var date = d.getDate();
        var day = d.getDay();
        var monthIndex = d.getMonth();
        var year = d.getFullYear();

        var days = ["Sun.", "Mon.", "Tue.", "Wed.", "Thur.", "Fri.", "Sat."];
        var months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        return days[day] + " " + months[monthIndex] + " " + date + ", " + year;
    }

    const papsmearRercord =
        papsmear.length === 0 ? (
            <tr>
                <td colSpan="3" className="text-center">
                    No papsmear record
                </td>
            </tr>
        ) : (
            papsmear.map(p => (
                <tr key={p.cytopathology_id}>
                    <td>{convertTime(p.consultation_date)}</td>

                    <td
                        className="text-nowrap"
                        style={{
                            maxWidth: "250px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}
                    >
                        {p.notes_and_suggestions
                            .split("\n")
                            .map((item, key) => {
                                return (
                                    <span key={key}>
                                        {item}
                                        <br />
                                    </span>
                                );
                            })}
                    </td>

                    <td className="text-nowrap">
                        <Button
                            title="Edit Consultation"
                            color="warning"
                            size="sm"
                            className="mr-1"
                            onClick={() =>
                                action(p.cytopathology_id, "modalEditPapsmear")
                            }
                        >
                            <i className="fa fa-edit" />
                        </Button>
                        <Button
                            title="Delete Consultation"
                            color="danger"
                            size="sm"
                            className="mr-1"
                            onClick={() =>
                                action(
                                    p.cytopathology_id,
                                    "modalDeletePapsmear"
                                )
                            }
                        >
                            <i className="fa fa-trash" />
                        </Button>
                    </td>
                </tr>
            ))
        );

    return (
        <>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th className="text-nowrap" style={{ width: "150px" }}>
                            {" "}
                            Consultation Date{" "}
                        </th>

                        <th className="text-nowrap border">Remarks</th>

                        <th
                            style={{
                                width: "100px"
                            }}
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>{papsmearRercord}</tbody>
            </Table>
            <hr />
            <Button
                color="primary"
                className="mt-2"
                onClick={() => toggleModal("modalAddPapsmear")}
            >
                <i className="fa fa-plus" aria-hidden="true" /> New Papsmear
                Consultation
            </Button>
        </>
    );
};

export default PapsmearData;
