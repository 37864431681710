import React, { useState } from "react";
import { Link } from "react-router-dom";

//ui components
import { Container, Col, FormGroup, Label, Input, Button } from "reactstrap";

import http from "./../services/httpService";
import { apiUrl } from "./../config.json";

const Login = props => {
    const [state, setState] = useState({
        email: "",
        password: "",
        name: "",
        message: ""
    });

    function handleChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        const body = {
            name: state.name,
            email: state.email,
            password: state.password
        };

        http.post(apiUrl + "/auth/signup", body)
            .then(response => {
                console.log(response);
                props.history.push("/login");
            })
            .catch(err => {
                console.log(err);
                return setState({
                    ...state,
                    message: "Server Error"
                });
            });
    }

    return (
        <div className="container-fluid pt-3" style={containerStyle}>
            <div className="col-5 mx-auto bg-white mt-5" style={registerStyle}>
                <Container>
                    <Col className="mx-auto mt-5 p-2">
                        <img
                            src="image/logo.png"
                            alt=""
                            className="img-fluid"
                        />
                        <hr />

                        <form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={handleChange}
                                    value={state.name}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={handleChange}
                                    value={state.email}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={handleChange}
                                    value={state.password}
                                />
                            </FormGroup>

                            <Button
                                color="primary"
                                type="submit"
                                size="lg"
                                block
                            >
                                Create Account
                            </Button>
                        </form>
                        <small>
                            Already have an account?
                            <Link to="/login"> Login here.</Link>
                        </small>
                        {state.message ? <p> {state.message}</p> : null}
                        <br />
                        <br />
                    </Col>
                </Container>
            </div>
        </div>
    );
};

const registerStyle = {
    borderRadius: "10px",
    width: "410px",
    boxShadow: "0px 9px 37px 3px rgba(0,0,0,0.19)"
};

const containerStyle = {
    backgroundImage: "url('image/background.png')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    height: "100vh"
};

export default Login;
