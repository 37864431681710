import React, { Component } from "react";
import { Button, Tooltip } from "reactstrap";

class ButtonBottomRight extends Component {
  state = {
    tooltipOpen: false
  };

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  render() {
    return (
      <>
        <Button
          color="primary"
          size="lg"
          id="btnBottomRight"
          onClick={this.props.onclick}
        >
          <i className="fa fa-plus" aria-hidden="true" />
        </Button>

        <Tooltip
          placement="right"
          isOpen={this.state.tooltipOpen}
          target="btnBottomRight"
          toggle={this.toggle}
        >
          {this.props.tooltipText}
        </Tooltip>
      </>
    );
  }
}

export default ButtonBottomRight;
