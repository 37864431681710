import React from "react";
import Celso_Sign from "../../img/celso_sign.png";

const FormPrintHistopathology = ({ formData, patient }) => {
    function convertToWholeDate(datetime) {
        if (datetime) {
            var d = new Date(datetime);

            var date = d.getDate();
            var monthIndex = d.getMonth();
            var year = d.getFullYear();

            var months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ];
            return months[monthIndex] + " " + date + ", " + year;
        } else {
            return "-";
        }
    }

    return (
        <div className="col-12 main">
            <div className="text-center histopathologyHeader">
                <p className="h2 primary font-weight-bold mb-0">
                    {" "}
                    CELSO S. RAMOS, M.D., F.P.S.P{" "}
                </p>
                <p className=" primary">
                    Diplomate, Phil Board of Pathology (Anatomic & Clinical)
                    <br /> Fellow, Phil, Society of the Pathologists.
                </p>
            </div>

            <div className="histopathologyBody">
                <hr className="header_divider" />

                <div className="text-center">
                    <p className="mb-0 primary">
                        SURGICAL PATHOLOGY CONSULTATION REPORT
                    </p>
                </div>

                <hr />

                <div className="row mb-4">
                    <div className="col-6">
                        <li className="mb-1">
                            <span className="primary mr-1">Patient:</span>
                            <span>{patient.name}</span>
                        </li>

                        <li className="mb-1">
                            <span className="primary mr-1">Address:</span>
                            <span>{patient.address}</span>
                        </li>

                        <li className="mb-1">
                            <span className="primary mr-1">
                                Referring Hospital:
                            </span>
                            <span>{formData.referring_hospital}</span>
                        </li>

                        <li className="mb-1">
                            <span className="primary mr-1">Impression:</span>
                            <span>{formData.impression}</span>
                        </li>
                    </div>

                    <div className="col-6">
                        <li className="mb-1">
                            <span className="primary mr-1">Age:</span>
                            <span>{patient.age === 0 ? "" : patient.age}</span>
                        </li>

                        <li className="mb-1">
                            <span className="primary mr-1">Sex:</span>
                            <span>{patient.sex}</span>
                        </li>

                        <li className="mb-1">
                            <span className="primary mr-1">Referred by:</span>
                            <span>{formData.referred_by}</span>
                        </li>

                        <li className="mb-1">
                            <span className="primary mr-1">Date:</span>
                            <span>
                                {convertToWholeDate(formData.consultation_date)}
                            </span>
                        </li>
                    </div>
                </div>

                <div>
                    <span className="primary font-weight-bold mr-1">
                        CLINICAL DATA:
                    </span>
                    <span>{formData.clinical_data}</span>
                </div>

                <div>
                    <span className="primary font-weight-bold mr-1">
                        SPECIMEN AND PROCEDURE DONE:
                    </span>
                    <span>{formData.specimen}</span>
                </div>

                <hr />

                <p className="primary font-weight-bold">FINAL DIAGNOSIS:</p>
                <div className="ml-5">
                    {formData.final_diagnosis.split("\n").map((item, key) => {
                        return (
                            <span key={key}>
                                {item}
                                <br />
                            </span>
                        );
                    })}
                </div>

                <hr />
                <p className="primary font-weight-bold">
                    MACROSCOPIC AND MICROSCOPIC EXAMINATION:
                </p>
                <div className="ml-5 mme">
                    {formData.macro_micro_examination
                        .split("\n")
                        .map((item, key) => {
                            return (
                                <span key={key}>
                                    {item}
                                    <br />
                                </span>
                            );
                        })}
                </div>

                <div className="footer">
                    <div className="row">
                        <div className="col-7">
                            <div
                                className="text-left"
                                style={{
                                    marginTop: "50px"
                                }}
                            >
                                <span className="primary">
                                    <span style={{ fontWeight: "bold" }}>
                                        Released Date:
                                    </span>{" "}
                                    <span>
                                        {convertToWholeDate(
                                            formData.released_date
                                        )}
                                    </span>
                                </span>
                                <br />

                                <span className="primary">
                                    <span style={{ fontWeight: "bold" }}>
                                        Date Collected:
                                    </span>{" "}
                                    <span>
                                        {convertToWholeDate(
                                            formData.date_collected
                                        )}
                                    </span>
                                </span>
                                <br />

                                <span className="primary">
                                    <span style={{ fontWeight: "bold" }}>
                                        Accession No.:
                                    </span>{" "}
                                    <span>{formData.accession_no}</span>
                                </span>
                            </div>
                        </div>

                        <div className="col-5">
                            <div className="doctorSignature">
                                <img
                                    src={Celso_Sign}
                                    className="img-fluid"
                                    width="50%"
                                    alt="sign"
                                    style={{
                                        marginBottom: "-64px",
                                        marginLeft: "70px"
                                    }}
                                />
                                <p className="primary">
                                    CELSO S. RAMOS M.D., FPSP, MBAH
                                </p>
                                PRC License no: 46296
                                <br />
                                Anatomic and Clinical Pathologist
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormPrintHistopathology;
