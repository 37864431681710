import React from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

import { Link } from "react-router-dom";

import { getCurrentUser } from "./../../auth/auth-utils/authHelper";

export default class Example extends React.Component {
    state = {
        isOpen: false,
        currentUser: ""
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    componentDidMount() {
        const currentUser = getCurrentUser();
        this.setState({
            currentUser: currentUser
        });
    }

    render() {
        return (
            <div>
                <Navbar color="white" dark expand="md" className="py-0" style={navbarStyle}>

                    <NavbarBrand href="/">
                       <img src="/image/logo.png" alt="logo" height="50px"/>
                    </NavbarBrand>       

                    <NavbarToggler onClick={this.toggle} />                    
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <UncontrolledDropdown nav inNavbar>

                                <DropdownToggle nav caret className="text-primary">
                                    {this.state.currentUser.name}
                                </DropdownToggle>

                                <DropdownMenu right>
                                    <Link to="/logout">
                                        <DropdownItem>Logout</DropdownItem>
                                    </Link>
                                    <Link to="/change">
                                        <DropdownItem>Change password</DropdownItem>
                                    </Link>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>

                </Navbar>
            </div>
        );
        
    }
}

const navbarStyle = {
    boxShadow: "0px -1px 21px 0px rgba(0,0,0,0.75)",
}
