import React, { useEffect } from 'react'

//utils
import { logOut } from './auth-utils/authHelper'

const Logout = props => {

    useEffect(() => {
        logOut()
    }, [])

    return <> </>;
}

export default Logout;