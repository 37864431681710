import React, { Component } from "react";
import { Link } from "react-router-dom";

import Main from "./Main";
import FormEditPatient from "./forms/FormEditPatient";

import FormAddPapsmear from "./forms/FormAddPapsmear";
import FormEditPapsmear from "./forms/FormEditPapsmear";
import FormDeletePapsmear from "./forms/FormDeletePapsmear";

import FormAddHistopathology from "./forms/FormAddHistopathology";
import FormEditHistopathology from "./forms/FormEditHistopathology";
import FormDeleteHistopathology from "./forms/FormDeleteHistopathology";

import PatientDetails from "./prcomponents/PatientDetails";
import PapsmearData from "./prcomponents/PapsmearData";
import HistopathologyData from "./prcomponents/HistopathologyData";
import OthersData from "./prcomponents/OthersData";
import {
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from "reactstrap";
import classnames from "classnames";

//utils
import http from "./../services/httpService";
import { apiUrl } from "./../config.json";

import { getCurrentUser } from "./../auth/auth-utils/authHelper";

export default class PatientRecord extends Component {
    state = {
        pageLoading: true,
        activeTab: "1",
        modalEditPatient: false,
        modalAddPapsmear: false,
        modalEditPapsmear: false,
        modalDeletePapsmear: false,
        modalAddHistopathology: false,
        modalEditHistopathology: false,
        modalDeleteHistopathology: false,
        patientRecord: {
            details: {
                patient_id: "-",
                lastname: "-",
                firstname: "-",
                middlename: "-",
                address: "-",
                age: "-",
                sex: "-",
                civil_status: "-"
            },
            papsmear: [],
            histopathologies: [],
            others: []
        },
        selectedPapsmear: {},
        selectedHistopathology: {}
    };

    componentDidMount() {
        const user = getCurrentUser();
        if (!user) {
            return this.props.history.push("/login");
        }

        const patient_id = this.props.match.params.patient_id;

        //get patient record
        http.get(apiUrl + "/patient/" + patient_id)
            .then(response => {
                if (response.status === 200 && response.statusText === "OK") {
                    const {
                        patient_id,
                        lastname,
                        firstname,
                        middlename,
                        address,
                        age,
                        sex,
                        civil_status,
                        cytopathologies: papsmear,
                        histopathologies,
                        others
                    } = response.data.patient;

                    const patientRecord = { ...this.state.patientRecord };

                    patientRecord.details = {
                        patient_id,
                        lastname,
                        firstname,
                        middlename,
                        address,
                        age,
                        sex,
                        civil_status
                    };
                    patientRecord.papsmear = papsmear;
                    patientRecord.histopathologies = histopathologies;
                    patientRecord.others = others;

                    this.setState({
                        patientRecord,
                        pageLoading: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });

        // this.setState({
        //     latestPatientCreated: this.props.latestPatientCreated,
        // })

        // if(Object.keys(this.state.latestPatientCreated).length > 0){
        //     this.setState({showAlertPatient: true})
        //     setTimeout(() => {
        //         this.setState({
        //             latestPatientCreated: {},
        //             showAlertPatient: false
        //         })
        //     }, 2300)
        // }
    }

    editPatient = editedPatient => {
        let patientRecord = { ...this.state.patientRecord };
        patientRecord.details.lastname = editedPatient.lastname;
        patientRecord.details.firstname = editedPatient.firstname;
        patientRecord.details.middlename = editedPatient.middlename;
        patientRecord.details.address = editedPatient.address;
        patientRecord.details.age = editedPatient.age;
        patientRecord.details.sex = editedPatient.sex;
        patientRecord.details.civil_status = editedPatient.civil_status;
        this.setState({ patientRecord });
    };

    addPapsmear = newPapsmear => {
        let patientRecord = { ...this.state.patientRecord };
        patientRecord.papsmear = [...patientRecord.papsmear, newPapsmear];
        this.setState(prevState => ({
            patientRecord,
            modalAddPapsmear: !prevState.modalAddPapsmear
        }));
    };

    editPapsmear = editedPapsmear => {
        const patientRecord = { ...this.state.patientRecord };
        const newPapsmear = patientRecord.papsmear.map(papsmear => {
            if (papsmear.cytopathology_id === editedPapsmear.cytopathology_id) {
                papsmear.physician = editedPapsmear.physician;
                papsmear.ac_no = editedPapsmear.ac_no;
                papsmear.consultation_date = editedPapsmear.consultation_date;
                papsmear.hospital = editedPapsmear.hospital;
                papsmear.specimen = editedPapsmear.specimen;
                papsmear.specimen_cervical_smear =
                    editedPapsmear.specimen_cervical_smear;
                papsmear.specimen_viginal_smear =
                    editedPapsmear.specimen_viginal_smear;
                papsmear.specimen_others = editedPapsmear.specimen_others;
                papsmear.pertinent_data = editedPapsmear.pertinent_data;
                papsmear.specimen_adequacy = editedPapsmear.specimen_adequacy;
                papsmear.general_categorization_date_requested =
                    editedPapsmear.general_categorization_date_requested;
                papsmear.general_categorization_negative =
                    editedPapsmear.general_categorization_negative;
                papsmear.general_categorization_epithelial =
                    editedPapsmear.general_categorization_epithelial;
                papsmear.general_categorization_others =
                    editedPapsmear.general_categorization_others;
                papsmear.automated_review = editedPapsmear.automated_review;
                papsmear.diagnosis_non_neoplastic =
                    editedPapsmear.diagnosis_non_neoplastic;
                papsmear.diagnosis_negative_intraepithelial =
                    editedPapsmear.diagnosis_negative_intraepithelial;
                papsmear.organisms = editedPapsmear.organisms;
                papsmear.organisms_count = editedPapsmear.organisms_count;
                papsmear.non_neoplastic_findings_reactive_assoc_with =
                    editedPapsmear.non_neoplastic_findings_reactive_assoc_with;
                papsmear.non_neoplastic_findings_reactive =
                    editedPapsmear.non_neoplastic_findings_reactive;
                papsmear.non_neoplastic_findings_inflammation =
                    editedPapsmear.non_neoplastic_findings_inflammation;
                papsmear.non_neoplastic_findings_radiation =
                    editedPapsmear.non_neoplastic_findings_radiation;
                papsmear.non_neoplastic_findings_uid =
                    editedPapsmear.non_neoplastic_findings_uid;
                papsmear.non_neoplastic_findings_other_nonneoplastic =
                    editedPapsmear.non_neoplastic_findings_other_nonneoplastic;
                papsmear.non_neoplastic_findings_atrophy =
                    editedPapsmear.non_neoplastic_findings_atrophy;
                papsmear.non_neoplastic_findings_others =
                    editedPapsmear.non_neoplastic_findings_others;
                papsmear.inflammation = editedPapsmear.inflammation;
                papsmear.epi_abnormalities = editedPapsmear.epi_abnormalities;
                papsmear.epi_abnormalities_squamouscell =
                    editedPapsmear.epi_abnormalities_squamouscell;
                papsmear.epi_abnormalities_squamouscell_atypical =
                    editedPapsmear.epi_abnormalities_squamouscell_atypical;
                papsmear.epi_abnormalities_squamouscell_atypical_ascus =
                    editedPapsmear.epi_abnormalities_squamouscell_atypical_ascus;
                papsmear.epi_abnormalities_squamouscell_atypical_asch =
                    editedPapsmear.epi_abnormalities_squamouscell_atypical_asch;
                papsmear.epi_abnormalities_squamouscell_lsil =
                    editedPapsmear.epi_abnormalities_squamouscell_lsil;
                papsmear.epi_abnormalities_squamouscell_lsil_hpv =
                    editedPapsmear.epi_abnormalities_squamouscell_lsil_hpv;
                papsmear.epi_abnormalities_squamouscell_lsil_cin1 =
                    editedPapsmear.epi_abnormalities_squamouscell_lsil_cin1;
                papsmear.epi_abnormalities_squamouscell_hsil =
                    editedPapsmear.epi_abnormalities_squamouscell_hsil;
                papsmear.epi_abnormalities_squamouscell_hsil_cin2 =
                    editedPapsmear.epi_abnormalities_squamouscell_hsil_cin2;
                papsmear.epi_abnormalities_squamouscell_hsil_cin3 =
                    editedPapsmear.epi_abnormalities_squamouscell_hsil_cin3;
                papsmear.epi_abnormalities_squamouscell_hsil_withfeature =
                    editedPapsmear.epi_abnormalities_squamouscell_hsil_withfeature;
                papsmear.epi_abnormalities_squamouscell_carcinoma =
                    editedPapsmear.epi_abnormalities_squamouscell_carcinoma;
                papsmear.epi_abnormalities_glandularcell =
                    editedPapsmear.epi_abnormalities_glandularcell;
                papsmear.epi_abnormalities_glandularcell_atypical =
                    editedPapsmear.epi_abnormalities_glandularcell_atypical;
                papsmear.epi_abnormalities_glandularcell_atypical_endocervical =
                    editedPapsmear.epi_abnormalities_glandularcell_atypical_endocervical;
                papsmear.epi_abnormalities_glandularcell_atypical_endometrial =
                    editedPapsmear.epi_abnormalities_glandularcell_atypical_endometrial;
                papsmear.epi_abnormalities_glandularcell_atypical_situ =
                    editedPapsmear.epi_abnormalities_glandularcell_atypical_situ;
                papsmear.epi_abnormalities_glandularcell_atypicalglandular =
                    editedPapsmear.epi_abnormalities_glandularcell_atypicalglandular;
                papsmear.epi_abnormalities_glandularcell_atypicalglandular_endocervical =
                    editedPapsmear.epi_abnormalities_glandularcell_atypicalglandular_endocervical;
                papsmear.epi_abnormalities_glandularcell_atypicalglandular_glanular =
                    editedPapsmear.epi_abnormalities_glandularcell_atypicalglandular_glanular;
                papsmear.epi_abnormalities_glandularcell_adenocarcinoma =
                    editedPapsmear.epi_abnormalities_glandularcell_adenocarcinoma;
                papsmear.epi_abnormalities_glandularcell_adenocarcinoma_endocervical =
                    editedPapsmear.epi_abnormalities_glandularcell_adenocarcinoma_endocervical;
                papsmear.epi_abnormalities_glandularcell_adenocarcinoma_endometrial =
                    editedPapsmear.epi_abnormalities_glandularcell_adenocarcinoma_endometrial;
                papsmear.epi_abnormalities_glandularcell_adenocarcinoma_extrauterine =
                    editedPapsmear.epi_abnormalities_glandularcell_adenocarcinoma_extrauterine;
                papsmear.epi_abnormalities_glandularcell_adenocarcinoma_notspecified =
                    editedPapsmear.epi_abnormalities_glandularcell_adenocarcinoma_notspecified;
                papsmear.other_malignant_neoplasms =
                    editedPapsmear.other_malignant_neoplasms;
                papsmear.notes_and_suggestions =
                    editedPapsmear.notes_and_suggestions;
                return papsmear;
            }
            return papsmear;
        });

        patientRecord.papsmear = newPapsmear;
        this.setState({ patientRecord });
    };

    deletePapsmear = cytopathology_id => {
        const patientRecord = { ...this.state.patientRecord };
        const newPapsmear = patientRecord.papsmear.filter(papsmear => {
            return papsmear.cytopathology_id !== cytopathology_id;
        });

        patientRecord.papsmear = newPapsmear;

        this.setState({
            patientRecord,
            modalDeletePapsmear: false
        });
    };

    addHistopathology = newHistopathology => {
        let patientRecord = { ...this.state.patientRecord };
        patientRecord.histopathologies = [
            ...patientRecord.histopathologies,
            newHistopathology
        ];
        this.setState(prevState => ({
            patientRecord,
            modalAddHistopathology: !prevState.modalAddHistopathology
        }));
    };

    editHistopathology = editedHistopathology => {
        const patientRecord = { ...this.state.patientRecord };
        const newHistopathologies = patientRecord.histopathologies.map(
            histopathology => {
                if (
                    histopathology.histopathology_id ===
                    editedHistopathology.histopathology_id
                ) {
                    histopathology.consultation_date =
                        editedHistopathology.consultation_date;
                    histopathology.referred_by =
                        editedHistopathology.referred_by;
                    histopathology.impression = editedHistopathology.impression;
                    histopathology.address = editedHistopathology.address;
                    histopathology.referring_hospital =
                        editedHistopathology.referring_hospital;
                    histopathology.clinical_data =
                        editedHistopathology.clinical_data;
                    histopathology.specimen = editedHistopathology.specimen;
                    histopathology.final_diagnosis =
                        editedHistopathology.final_diagnosis;
                    histopathology.macro_micro_examination =
                        editedHistopathology.macro_micro_examination;
                    histopathology.released_date =
                        editedHistopathology.released_date;
                    histopathology.date_collected =
                        editedHistopathology.date_collected;
                    histopathology.accession_no =
                        editedHistopathology.accession_no;

                    return histopathology;
                }
                return histopathology;
            }
        );

        patientRecord.histopathologies = newHistopathologies;
        this.setState({ patientRecord });
    };

    deleteHistopathology = histopathology_id => {
        const patientRecord = { ...this.state.patientRecord };
        const newHistopathologies = patientRecord.histopathologies.filter(
            histopathology => {
                return histopathology.histopathology_id !== histopathology_id;
            }
        );

        patientRecord.histopathologies = newHistopathologies;

        this.setState({
            patientRecord,
            modalDeleteHistopathology: false
        });
    };

    setSelectedPapsmear = (id, modal) => {
        const selectedPapsmear = this.state.patientRecord.papsmear.filter(
            papsmear => {
                return papsmear.cytopathology_id === id;
            }
        );

        this.setState(prevState => ({
            selectedPapsmear: selectedPapsmear[0],
            [modal]: !prevState[modal]
        }));
    };

    setSelectedHistopathology = (id, modal) => {
        const selectedHistopathology = this.state.patientRecord.histopathologies.filter(
            histopathology => {
                return histopathology.histopathology_id === id;
            }
        );

        this.setState(prevState => ({
            selectedHistopathology: selectedHistopathology[0],
            [modal]: !prevState[modal]
        }));
    };

    toggleModal = modal => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    };

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    // others
    addOthers = payload => {
        let patientRecord = { ...this.state.patientRecord };
        patientRecord.others = [...patientRecord.others, payload];
        this.setState({ patientRecord });
    };

    updateOthers = payload => {
        let patientRecord = { ...this.state.patientRecord };
        const updatedOthers = patientRecord.others.map(x => {
            if (x.others_id === payload.currentCase) {
                x.referred_by = payload.inputs.referred_by;
                x.procedure = payload.inputs.procedure;
                x.result = payload.inputs.result;
            }
            return x;
        });
        this.setState({
            ...this.state.patientRecord,
            others: [...this.state.patientRecord.others, updatedOthers]
        });
        console.log(updatedOthers, payload);
    };

    deleteOthers = payload => {
        let patientRecord = { ...this.state.patientRecord };
        patientRecord.others = [
            ...patientRecord.others.filter(x => x.others_id !== payload)
        ];
        this.setState({ patientRecord });
    };

    render() {
        return (
            <>
                <Main>
                    <h3 className="h3">Patient Record</h3>

                    <Link
                        to="/home"
                        className="float-right"
                        style={{ marginTop: "-37px" }}
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                        Back to Home
                    </Link>

                    <hr />
                    {this.state.pageLoading ? (
                        <div className="text-center mt-4">
                            <Spinner size="sm" color="primary" />
                            <br />
                            Loading Patient Record...
                        </div>
                    ) : (
                        <Row>
                            <Col md={4}>
                                <PatientDetails
                                    patient={this.state.patientRecord.details}
                                    toggleModal={this.toggleModal}
                                />
                            </Col>
                            <Col md={8}>
                                <Card>
                                    <CardBody>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                    className={classnames({
                                                        active:
                                                            this.state
                                                                .activeTab ===
                                                            "1"
                                                    })}
                                                    onClick={() => {
                                                        this.toggleTab("1");
                                                    }}
                                                >
                                                    Papsmear
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                    className={classnames({
                                                        active:
                                                            this.state
                                                                .activeTab ===
                                                            "2"
                                                    })}
                                                    onClick={() => {
                                                        this.toggleTab("2");
                                                    }}
                                                >
                                                    Histopathology
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                    className={classnames({
                                                        active:
                                                            this.state
                                                                .activeTab ===
                                                            "3"
                                                    })}
                                                    onClick={() => {
                                                        this.toggleTab("3");
                                                    }}
                                                >
                                                    Others
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent
                                            activeTab={this.state.activeTab}
                                        >
                                            <TabPane tabId="1" className="p-4">
                                                <PapsmearData
                                                    papsmear={
                                                        this.state.patientRecord
                                                            .papsmear
                                                    }
                                                    toggleModal={
                                                        this.toggleModal
                                                    }
                                                    action={
                                                        this.setSelectedPapsmear
                                                    }
                                                />
                                            </TabPane>
                                            <TabPane tabId="2" className="p-4">
                                                <HistopathologyData
                                                    histopathology={
                                                        this.state.patientRecord
                                                            .histopathologies
                                                    }
                                                    toggleModal={
                                                        this.toggleModal
                                                    }
                                                    action={
                                                        this
                                                            .setSelectedHistopathology
                                                    }
                                                />
                                            </TabPane>
                                            <TabPane tabId="3" className="p-4">
                                                <OthersData
                                                    updateOthers={
                                                        this.updateOthers
                                                    }
                                                    deleteOthers={
                                                        this.deleteOthers
                                                    }
                                                    addOthers={this.addOthers}
                                                    patient={
                                                        this.state.patientRecord
                                                            .details
                                                    }
                                                    others={
                                                        this.state.patientRecord
                                                            .others
                                                    }
                                                />
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Main>

                {/* Modal Edit Patient */}
                <Modal
                    isOpen={this.state.modalEditPatient}
                    fade={false}
                    toggle={() => this.toggleModal("modalEditPatient")}
                    returnFocusAfterClose={this.state.focusAfterClose}
                >
                    <ModalHeader
                        toggle={() => this.toggleModal("modalEditPatient")}
                    >
                        Edit Patient
                    </ModalHeader>
                    <ModalBody>
                        <FormEditPatient
                            patient={this.state.patientRecord.details}
                            editPatient={this.editPatient}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => this.toggleModal("modalEditPatient")}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Modal Add Papsmear */}
                <Modal
                    isOpen={this.state.modalAddPapsmear}
                    fade={false}
                    toggle={() => this.toggleModal("modalAddPapsmear")}
                    returnFocusAfterClose={this.state.focusAfterClose}
                    size="lg"
                >
                    <ModalHeader
                        toggle={() => this.toggleModal("modalAddPapsmear")}
                    >
                        Cytopathology Consultation
                    </ModalHeader>
                    <ModalBody>
                        <FormAddPapsmear
                            patient={this.state.patientRecord.details}
                            addPapsmear={this.addPapsmear}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => this.toggleModal("modalAddPapsmear")}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Modal Edit Papsmear */}
                <Modal
                    isOpen={this.state.modalEditPapsmear}
                    fade={false}
                    toggle={() => this.toggleModal("modalEditPapsmear")}
                    returnFocusAfterClose={this.state.focusAfterClose}
                    size="lg"
                >
                    <ModalHeader
                        toggle={() => this.toggleModal("modalEditPapsmear")}
                    >
                        Edit Cytopathology Consultation
                    </ModalHeader>
                    <ModalBody>
                        <FormEditPapsmear
                            patient={this.state.patientRecord.details}
                            papsmear={this.state.selectedPapsmear}
                            editPapsmear={this.editPapsmear}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() =>
                                this.toggleModal("modalEditPapsmear")
                            }
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Modal Delete Papsmear */}
                <Modal
                    isOpen={this.state.modalDeletePapsmear}
                    fade={false}
                    toggle={() => this.toggleModal("modalDeletePapsmear")}
                    returnFocusAfterClose={this.state.focusAfterClose}
                >
                    <ModalHeader
                        toggle={() => this.toggleModal("modalDeletePapsmear")}
                    >
                        Delete Cytopathology Consultation
                    </ModalHeader>
                    <ModalBody>
                        <FormDeletePapsmear
                            papsmear={this.state.selectedPapsmear}
                            deletePapsmear={this.deletePapsmear}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() =>
                                this.toggleModal("modalDeletePapsmear")
                            }
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Modal Add Histopathology */}
                <Modal
                    isOpen={this.state.modalAddHistopathology}
                    fade={false}
                    toggle={() => this.toggleModal("modalAddHistopathology")}
                    returnFocusAfterClose={this.state.focusAfterClose}
                    size="lg"
                    id="modalHistopathology"
                >
                    <ModalHeader
                        toggle={() =>
                            this.toggleModal("modalAddHistopathology")
                        }
                    >
                        Histopathology Consultation
                    </ModalHeader>
                    <ModalBody>
                        <FormAddHistopathology
                            patient={this.state.patientRecord.details}
                            addHistopathology={this.addHistopathology}
                            templates={this.props.templates}
                            addTemplate={this.props.addTemplate}
                            editTemplate={this.props.editTemplate}
                            deleteTemplate={this.props.deleteTemplate}
                            searchTemplate={this.props.searchTemplate}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() =>
                                this.toggleModal("modalAddHistopathology")
                            }
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Modal Edit Histopathology */}
                <Modal
                    isOpen={this.state.modalEditHistopathology}
                    fade={false}
                    toggle={() => this.toggleModal("modalEditHistopathology")}
                    returnFocusAfterClose={this.state.focusAfterClose}
                    size="lg"
                >
                    <ModalHeader
                        toggle={() =>
                            this.toggleModal("modalEditHistopathology")
                        }
                    >
                        Edit Histopathology Consultation
                    </ModalHeader>
                    <ModalBody>
                        <FormEditHistopathology
                            patient={this.state.patientRecord.details}
                            histopathology={this.state.selectedHistopathology}
                            editHistopathology={this.editHistopathology}
                            templates={this.props.templates}
                            addTemplate={this.props.addTemplate}
                            editTemplate={this.props.editTemplate}
                            deleteTemplate={this.props.deleteTemplate}
                            searchTemplate={this.props.searchTemplate}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() =>
                                this.toggleModal("modalEditHistopathology")
                            }
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Modal Delete Histopathology */}
                <Modal
                    isOpen={this.state.modalDeleteHistopathology}
                    fade={false}
                    toggle={() => this.toggleModal("modalDeleteHistopathology")}
                    returnFocusAfterClose={this.state.focusAfterClose}
                >
                    <ModalHeader
                        toggle={() =>
                            this.toggleModal("modalDeleteHistopathology")
                        }
                    >
                        Delete Histopathology Consultation
                    </ModalHeader>
                    <ModalBody>
                        <FormDeleteHistopathology
                            histopathology={this.state.selectedHistopathology}
                            deleteHistopathology={this.deleteHistopathology}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() =>
                                this.toggleModal("modalDeleteHistopathology")
                            }
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}
