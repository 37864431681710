import React, { Component } from "react";
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
    Col,
    Form,
    FormText,
    Label,
    Input,
    Button,
    Alert,
    FormGroup,
    Table
} from "reactstrap";
import classnames from "classnames";

import FormAddTemplate from "./FormAddTemplate";
import FormEditTemplate from "./FormEditTemplate";
import FormPrintHistopathology from "./FormPrintHistopathology";

import http from "../../services/httpService";
import { apiUrl } from "../../config.json";

export default class FormAddHistopathology extends Component {
    state = {
        templates: [],
        showAlert: false,
        formLoading: false,
        activeTab: "1",
        patient: {
            name: "",
            age: "",
            sex: "",
            address: ""
        },
        fontSize: 22,
        formData: {
            consultation_date: null,
            referred_by: "",
            impression: "",
            referring_hospital: "",
            clinical_data: "",
            specimen: "",
            final_diagnosis: "",
            macro_micro_examination: "",
            released_date: null,
            date_collected: null,
            accession_no: ""
        },
        formErrors: {
            consultation_date: ""
        },
        editingTemplate: false,
        addingTemplate: false,
        selectedTemplate: null
    };

    txtDiagnosis = React.createRef();

    //axios cancellation
    CancelToken = http.cancelToken;
    source = this.CancelToken.source();

    componentDidMount() {
        const {
            patient_id,
            lastname,
            firstname,
            middlename,
            age,
            sex,
            address
        } = this.props.patient;
        let { patient, formData } = { ...this.state };
        patient.name = `${lastname} ${firstname} ${middlename}`;
        patient.age = age;
        patient.sex = sex;
        patient.address = address;

        formData.patient_id = patient_id;

        this.setState({
            patient,
            formData,
            templates: this.props.templates,
            templatesCopy: this.props.templates
        });

        http.get(`${apiUrl}/template`).then(response => {
            this.setState({ templates: response.data.templates });
        });
    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    handleChangeInput = e => {
        let formData = { ...this.state.formData };
        formData[e.target.name] = e.target.value;
        this.setState({ formData });
    };

    handleBlur = e => {
        let formErrors = { ...this.state.formErrors };
        if (e.target.value.trim() !== "") {
            formErrors[e.target.name] = "";
        }
        this.setState({ formErrors });
    };

    validateInput = () => {
        const { consultation_date } = this.state.formData;

        let errors = {};
        if (!consultation_date) {
            errors.consultation_date = "Please select consultation date";
        }
        return Object.keys(errors).length === 0 ? null : errors;
    };

    handleSubmit = e => {
        e.preventDefault();
        const formErrors = this.validateInput();
        if (formErrors) {
            this.setState({
                formErrors
            });
            alert(
                "There's an error(s) on the formData. Please scan the form and check."
            );
        } else {
            this.setState(prevState => ({
                formLoading: !prevState.formLoading
            }));

            http.post(
                apiUrl + "/surgical",
                { ...this.state.formData },
                {
                    cancelToken: this.source.token
                }
            )
                .then(response => {
                    this.props.addHistopathology(response.data.surgical);

                    let winPrint = window.open(
                        "",
                        "",
                        "left=55,top=0,width=1200,height=600,toolbar=0,scrollbars=0,status=0"
                    );
                    winPrint.document.write(
                        `<html><head><link rel="stylesheet"
                            type="text/css"
                            href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                            integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                            crossorigin="anonymous"/>
                            <style>
                                *{
                                    list-style-type: none;
                                    
                                }

                                .mme {
                                    font-size: ${this.state.fontSize}
                                }

                                body{
                                    height: 1375px;
                                }

                                .histopathologyBody{
                                    font-size:22px;
                                    position:relative;
                                }

                                .doctorSignature{
                                    margin-left: 50px;
                                }

                                .footer{
                                    position: absolute;
                                    top: 1210px;
                                    left: 0;
                                    width: 100%;
                                }

                                hr{
                                    border: 1px solid #0f0b30;
                                }

                                .header_divider {
                                    border: 2px solid #0f0b30;
                                }

                            </style>
                            </head><body>` +
                            document.getElementById(
                                "histopathologyPrintContent"
                            ).innerHTML +
                            `</body></html>`
                    );
                    winPrint.document.close();
                    setTimeout(() => {
                        winPrint.focus();
                        winPrint.print();
                        winPrint.close();
                    }, 1000);
                })
                .catch(error => {
                    alert(error);
                });
        }
    };

    showAddTemplateForm = () => {
        this.setState(prevState => ({
            addingTemplate: !prevState.addingTemplate
        }));
    };

    showEditTemplateForm = template_id => {
        const selectedTemplate = this.props.templates.filter(template => {
            return template.template_id === template_id;
        });

        this.setState(prevState => ({
            selectedTemplate: selectedTemplate[0],
            editingTemplate: !prevState.editingTemplate
        }));
    };

    useTemplate = template_id => {
        const selectedTemplate = this.props.templates.filter(template => {
            return template.template_id === template_id;
        });

        let formData = { ...this.state.formData };
        formData.final_diagnosis = selectedTemplate[0].diagnosis;
        formData.macro_micro_examination = selectedTemplate[0].examination;

        this.setState({
            formData,
            activeTab: "1"
        });

        setTimeout(() => {
            this.txtDiagnosis.current.focus();
        }, 10);
    };

    deleteTemplate = template_id => {
        http.delete(apiUrl + `/template/${template_id}`, {
            cancelToken: this.source.token
        })
            .then(response => {
                if (response.status === 200 && response.statusText === "OK") {
                    this.props.deleteTemplate(template_id);
                }
            })
            .catch(error => {
                alert(error);
            });
    };

    render() {
        const {
            formData,
            formLoading,
            editingTemplate,
            addingTemplate,
            patient
        } = this.state;

        const addHistopathologyButton = formLoading ? (
            <Button type="submit" disabled color="primary">
                Saving ...
            </Button>
        ) : (
            <Button type="submit" color="primary">
                <i className="fa fa-save" /> Save and Print
            </Button>
        );

        const templateContent =
            editingTemplate || addingTemplate ? (
                addingTemplate ? (
                    <FormAddTemplate
                        showAddTemplateForm={this.showAddTemplateForm}
                        addTemplate={this.props.addTemplate}
                    />
                ) : (
                    <FormEditTemplate
                        showEditTemplateForm={this.showEditTemplateForm}
                        editTemplate={this.props.editTemplate}
                        template={this.state.selectedTemplate}
                    />
                )
            ) : (
                <>
                    <Form inline className="mb-2">
                        <FormGroup>
                            <Label for="txtSearch" className="mr-sm-2">
                                Search Template
                            </Label>
                            <Input
                                type="text"
                                id="txtSearch"
                                name="accession_no"
                                placeholder="Search Template"
                                onChange={this.props.searchTemplate}
                            />
                        </FormGroup>
                    </Form>
                    <div className="float-right" style={{ marginTop: "-40px" }}>
                        <Button
                            size="sm"
                            color="primary"
                            title="Add Template"
                            onClick={this.showAddTemplateForm}
                        >
                            Add Template
                        </Button>
                    </div>
                    <div style={{ height: "300px", overflow: "auto" }}>
                        <Table bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Template Name</th>
                                    <th>Search Keys</th>
                                    <th style={{ width: "145px" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.templates.map(template => (
                                    <tr key={template.template_id}>
                                        <td>{template.template_name}</td>
                                        <td>{template.search_keys}</td>
                                        <td>
                                            <Button
                                                size="sm"
                                                color="primary"
                                                title="Use this Template"
                                                className="mr-1"
                                                onClick={() =>
                                                    this.useTemplate(
                                                        template.template_id
                                                    )
                                                }
                                            >
                                                Use
                                            </Button>
                                            <Button
                                                size="sm"
                                                color="warning"
                                                title="Edit Template"
                                                className="mr-1"
                                                onClick={() =>
                                                    this.showEditTemplateForm(
                                                        template.template_id
                                                    )
                                                }
                                            >
                                                <i className="fa fa-edit" />
                                            </Button>
                                            <Button
                                                size="sm"
                                                color="danger"
                                                title="Delete Template"
                                                onClick={() =>
                                                    this.deleteTemplate(
                                                        template.template_id
                                                    )
                                                }
                                            >
                                                <i className="fa fa-trash" />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </>
            );

        return (
            <>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: this.state.activeTab === "1"
                            })}
                            onClick={() => {
                                this.toggleTab("1");
                            }}
                        >
                            Consultation Record
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: this.state.activeTab === "2"
                            })}
                            onClick={() => {
                                this.toggleTab("2");
                            }}
                        >
                            Templates
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1" className="p-4">
                        <Form
                            className="papsmear-form"
                            onSubmit={this.handleSubmit}
                        >
                            <Row>
                                <Col md={6}>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="text"
                                            id="1"
                                            placeholder="Name"
                                            disabled
                                            value={patient.name}
                                        />
                                        <Label for="1">Patient Name</Label>
                                    </FormGroup>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="text"
                                            id="2"
                                            placeholder="Age"
                                            disabled
                                            value={patient.age}
                                        />
                                        <Label for="2">Age</Label>
                                    </FormGroup>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="text"
                                            id="3"
                                            placeholder="Sex"
                                            disabled
                                            value={patient.sex}
                                        />
                                        <Label for="3">Sex</Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="date"
                                            name="consultation_date"
                                            id="4"
                                            placeholder="Date"
                                            onChange={this.handleChangeInput}
                                            value={
                                                formData.consultation_date ===
                                                null
                                                    ? ""
                                                    : formData.consultation_date
                                            }
                                            invalid={
                                                this.state.formErrors
                                                    .consultation_date
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <Label for="4">Date</Label>
                                        <FormText>
                                            {
                                                this.state.formErrors
                                                    .consultation_date
                                            }
                                        </FormText>
                                    </FormGroup>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="text"
                                            id="5"
                                            name="referred_by"
                                            placeholder="Referred By"
                                            onChange={this.handleChangeInput}
                                            value={formData.referred_by}
                                        />
                                        <Label for="5">Referred By:</Label>
                                    </FormGroup>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="text"
                                            id="6"
                                            name="impression"
                                            placeholder="Impression"
                                            onChange={this.handleChangeInput}
                                            value={formData.impression}
                                        />
                                        <Label for="6">Impression:</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="text"
                                            id="7"
                                            name="address"
                                            placeholder="Address"
                                            value={patient.address}
                                            disabled
                                        />
                                        <Label for="7">Address:</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="text"
                                            id="8"
                                            name="referring_hospital"
                                            placeholder="Referring Hospital"
                                            onChange={this.handleChangeInput}
                                            value={formData.referring_hospital}
                                        />
                                        <Label for="8">
                                            Referring Hospital:
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="9">Clinical Data:</Label>
                                        <Input
                                            type="text"
                                            id="9"
                                            name="clinical_data"
                                            placeholder="Clinical Data"
                                            onChange={this.handleChangeInput}
                                            value={formData.clinical_data}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="10">
                                            Specimen and Procedure Done:
                                        </Label>
                                        <Input
                                            type="text"
                                            rows="3"
                                            name="specimen"
                                            placeholder="Specimen and Procedure Done"
                                            onChange={this.handleChangeInput}
                                            value={formData.specimen}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            <Button
                                size="sm"
                                color="primary"
                                onClick={() => {
                                    this.setState({ activeTab: "2" });
                                }}
                            >
                                Use template
                            </Button>
                            <hr />
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="11">Final Diagnosis:</Label>
                                        <Input
                                            type="textarea"
                                            rows="3"
                                            id="11"
                                            name="final_diagnosis"
                                            placeholder="Final Diagnosis"
                                            onChange={this.handleChangeInput}
                                            value={formData.final_diagnosis}
                                            innerRef={this.txtDiagnosis}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form inline className="float-right" style={{ marginTop: "-10px" }}>
                                        <Label for="fontSize" className="mr-3">
                                            Font Size:
                                        </Label>
                                        <Input 
                                            id="fontSize"
                                            type="select"
                                            value={this.state.fontSize}
                                            onChange={(e) => this.setState({ fontSize: e.target.value })}
                                            >
                                            <option value="22">22</option>
                                            <option value="18">18</option>
                                            <option value="16">16</option>
                                            <option value="14">14</option>
                                            <option value="12">12</option>
                                        </Input>
                                    </Form>
                                    <FormGroup>
                                        <Label for="12">
                                            Macroscopic and Microscopic
                                            Examination:
                                        </Label>
                                        <Input
                                            type="textarea"
                                            rows="3"
                                            id="12"
                                            name="macro_micro_examination"
                                            placeholder="Macroscopic and Microscopic Examination"
                                            onChange={this.handleChangeInput}
                                            value={
                                                formData.macro_micro_examination
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="date"
                                            id="13"
                                            name="released_date"
                                            placeholder="Release Date"
                                            onChange={this.handleChangeInput}
                                            value={
                                                formData.released_date === null
                                                    ? ""
                                                    : formData.released_date
                                            }
                                        />
                                        <Label for="13">Release Date</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="date"
                                            id="14"
                                            name="date_collected"
                                            placeholder="Date Collected"
                                            onChange={this.handleChangeInput}
                                            value={
                                                formData.date_collected === null
                                                    ? ""
                                                    : formData.date_collected
                                            }
                                        />
                                        <Label for="14">Date Collected</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className="form-label-group">
                                        <Input
                                            type="text"
                                            id="15"
                                            name="accession_no"
                                            placeholder="Accession No."
                                            onChange={this.handleChangeInput}
                                            value={formData.accession_no}
                                        />
                                        <Label for="15">Accession No.</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            {this.state.showAlert && (
                                <Alert fade={false} color="success">
                                    <i className="fa fa-check" /> Record
                                    Successfully Saved.
                                </Alert>
                            )}
                            <div className="text-center">
                                {addHistopathologyButton}
                            </div>
                        </Form>
                        {/* --- */}
                        <div
                            id="histopathologyPrintContent"
                            className="container d-none"
                        >
                            <FormPrintHistopathology
                                formData={formData}
                                patient={patient}
                            />
                        </div>
                    </TabPane>
                    <TabPane tabId="2" className="p-4">
                        <Row>
                            <Col md={12}>{templateContent}</Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </>
        );
    }
}
