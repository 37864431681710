import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";

import { getCurrentUser } from "./auth/auth-utils/authHelper";
import { REG_PATH } from "./config.json";
import http from "./services/httpService";
import { apiUrl } from "./config.json";

//custom components
import Login from "./auth/Login";
import Logout from "./auth/Logout";
import Register from "./auth/Register";
import ChangePassword from "./auth/ChangePassword";

import Home from "./components/Home";
import PatientRecord from "./components/PatientRecord";

class App extends Component {
    state = {
        currentUser: {},
        templates: [],
        templatesCopy: [],
        searchableCols: ["template_name", "search_keys"]
    };

    componentDidMount() {
        const currentUser = getCurrentUser();
        this.setState({ currentUser });

        //get histopahology templates
        http.get(apiUrl + "/template")
            .then(response => {
                this.setState({
                    templates: response.data.templates,
                    templatesCopy: response.data.templates
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    addTemplate = newTemplate => {
        const newTemplates = [...this.state.templatesCopy, newTemplate];
        this.setState({ templates: newTemplates, templatesCopy: newTemplates });
    };

    editTemplate = editedTemplate => {
        const newTemplates = this.state.templatesCopy.map(template => {
            if (template.template_id === editedTemplate.template_id) {
                template.template_name = editedTemplate.template_name;
                template.search_keys = editedTemplate.search_keys;
                template.diagnosis = editedTemplate.diagnosis;
                template.examination = editedTemplate.examination;

                return template;
            }
            return template;
        });

        this.setState({ templates: newTemplates, templatesCopy: newTemplates });
    };

    deleteTemplate = template_id => {
        const newTemplate = this.state.templatesCopy.filter(template => {
            return template.template_id !== template_id;
        });
        this.setState({ templates: newTemplate, templatesCopy: newTemplate });
    };

    searchOnColumn = (column, searchString) => {
        return this.state.templatesCopy.filter(row => {
            if (row[column]) {
                return row[column]
                    .toString()
                    .toLowerCase()
                    .includes(searchString);
            } else {
                return row;
            }
        });
    };

    //search on template table
    searchTemplate = e => {
        const searchString = e.target.value;
        if (!searchString) {
            //list all template
            this.setState(prevState => ({
                templates: prevState.templatesCopy
            }));
        } else {
            let searchResult = [];
            this.state.searchableCols.forEach(element => {
                searchResult.push(
                    ...this.searchOnColumn(element, searchString)
                );
            });

            this.setState({
                templates: [...new Set(searchResult)]
            });
        }
    };

    render() {
        return (
            <>
                <Router>
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route
                            exact
                            path="/change"
                            component={ChangePassword}
                        />

                        <Route
                            exact
                            path={`/${REG_PATH}`}
                            component={Register}
                        />
                        <Route exact path="/logout" component={Logout} />

                        <Route path="/home" component={Home} />

                        <Route
                            path="/record/:patient_id"
                            render={props => (
                                <PatientRecord
                                    {...props}
                                    templates={this.state.templates}
                                    addTemplate={this.addTemplate}
                                    editTemplate={this.editTemplate}
                                    deleteTemplate={this.deleteTemplate}
                                    searchTemplate={this.searchTemplate}
                                />
                            )}
                        />

                        <Redirect path="/" to="login" />
                    </Switch>
                </Router>
            </>
        );
    }
}

export default App;
