import React, { Component } from "react";
import Navbar from "./includes/NavBar";
import Footer from '../components/includes/Footer'
import { Card, CardBody } from "reactstrap";

export default class Main extends Component {
    render() {
        return (
            <>
                <Navbar />  
                    <div className="container mt-5 h-100" style={mainStyle}>
                        <Card className="bg-white" style={cartStyle}>
                            <CardBody>{this.props.children}</CardBody>
                        </Card>
                    </div>
                <Footer />
            </>
        );
    }
}

const mainStyle = {   
    minHeight: "calc(100vh - 156px)" 
}
const cartStyle = {
    boxShadow: "-3px 9px 56px -2px rgba(199,199,199,1)",   
}