import React from 'react'

import { Spinner, Container, Col, } from 'reactstrap';

const Authenticating = props => {
    return(
        <Container style={spinnerStyle}>
            <Col className="mx-auto mt-5 pt-5 text-center p-2"
                style={spinnerStyle}>
                <br/>
                <Spinner 
                    className="mt-4"
                    color="primary"/>
                <h4 color="primary mt-2">Authenticating</h4>            
            </Col>
        </Container>
    )
}

const spinnerStyle = {
    width: "330px",
    height: "299px"
}

export default Authenticating;