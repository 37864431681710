import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Row, Col, Form, FormText, Label, Input, Button } from "reactstrap";
import http from "../../services/httpService";
import { apiUrl } from "../../config.json";

class AddPatientForm extends Component {
    state = {
        showAlert: false,
        formLoading: false,
        formData: {
            lastname: "",
            firstname: "",
            middlename: "",
            address: "",
            age: 0,
            sex: "",
            civil_status: ""
        },
        formErrors: {
            lastname: "",
            firstname: "",
            middlename: "",
            address: "",
            age: "",
            sex: "",
            civil_status: ""
        }
    };

    //first element focus refs
    txtPatientLastname = React.createRef();

    //axios cancellation
    CancelToken = http.cancelToken;
    source = this.CancelToken.source();

    componentWillUnmount() {
        if (this.closeMessageTimer) {
            clearTimeout(this.closeMessageTimer);
        }
        this.source.cancel("Operation adding a patient cancelled!");
        this._mounted = false;
    }

    componentDidMount() {
        this._mounted = true;
        setTimeout(() => {
            this.txtPatientLastname.current.focus();
        }, 10);
    }

    handleChange = e => {
        let formData = { ...this.state.formData };
        formData[e.target.name] = e.target.value;
        this.setState({ formData });
    };

    handleBlur = e => {
        let formErrors = { ...this.state.formErrors };
        if (e.target.value.trim() !== "") {
            formErrors[e.target.name] = "";
        }
        this.setState({ formErrors });
    };

    validateInput = () => {
        const { lastname, firstname } = this.state.formData;

        let errors = {};
        if (lastname.trim() === "") {
            errors.lastname = "Please enter Family Name";
        }
        if (firstname.trim() === "") {
            errors.firstname = "Please enter First Name";
        }
        return Object.keys(errors).length === 0 ? null : errors;
    };

    resetForm = () => {
        if (this._mounted) {
            this.setState(prevState => ({
                formLoading: !prevState.formLoading,
                formData: {
                    lastname: "",
                    firstname: "",
                    middlename: "",
                    address: "",
                    age: "",
                    sex: "",
                    civil_status: ""
                }
            }));

            this.txtPatientLastname.current.focus();
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        const formErrors = this.validateInput();
        if (formErrors) {
            this.setState({
                formErrors
            });
        } else {
            this.setState(prevState => ({
                formLoading: !prevState.formLoading
            }));

            //post create and get result
            http.post(
                apiUrl + "/patient",
                { ...this.state.formData },
                {
                    cancelToken: this.source.token
                }
            )
                .then(response => {
                    console.dir("RESPONSE", response);
                    console.log(JSON.stringify(response));

                    this.props.history.push(
                        `/record/${response.data.patient.patient_id}`
                    );
                })
                .catch(error => {
                    alert(error);
                    this.resetForm();
                });
        }
    };

    render() {
        const addPatientButton = this.state.formLoading ? (
            <Button type="submit" disabled color="primary">
                Saving ...
            </Button>
        ) : (
            <Button type="submit" color="primary">
                <i className="fa fa-plus" aria-hidden="true" /> Add Patient
            </Button>
        );

        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                    <Row form>
                        <Col md={3}>
                            <Label for="txtPatientLastname">Family Name</Label>
                        </Col>
                        <Col md={9}>
                            <Input
                                type="text"
                                name="lastname"
                                id="txtPatientLastname"
                                onChange={this.handleChange}
                                autoComplete="off"
                                value={this.state.formData.lastname}
                                invalid={
                                    this.state.formErrors.lastname
                                        ? true
                                        : false
                                }
                                onBlur={this.handleBlur}
                                innerRef={this.txtPatientLastname}
                            />
                            <FormText>
                                {this.state.formErrors.lastname}
                            </FormText>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3}>
                            <Label for="txtPatientFirstname">First Name</Label>
                        </Col>
                        <Col md={9}>
                            <Input
                                type="text"
                                name="firstname"
                                id="txtPatientFirstname"
                                onChange={this.handleChange}
                                autoComplete="off"
                                value={this.state.formData.firstname}
                                invalid={
                                    this.state.formErrors.firstname
                                        ? true
                                        : false
                                }
                                onBlur={this.handleBlur}
                            />
                            <FormText>
                                {this.state.formErrors.firstname}
                            </FormText>
                        </Col>
                    </Row>

                    <Row form>
                        <Col md={3}>
                            <Label for="txtPatientMiddlename">Middle</Label>
                        </Col>
                        <Col md={9}>
                            <Input
                                type="text"
                                name="middlename"
                                id="txtPatientFirstname"
                                onChange={this.handleChange}
                                autoComplete="off"
                                value={this.state.formData.middlename}
                                invalid={
                                    this.state.formErrors.middlename
                                        ? true
                                        : false
                                }
                                onBlur={this.handleBlur}
                            />
                            <FormText>
                                {this.state.formErrors.middlename}
                            </FormText>
                        </Col>
                    </Row>

                    <Row form>
                        <Col md={3}>
                            <Label for="txtPatientAge">Age</Label>
                        </Col>
                        <Col md={9}>
                            <Input
                                type="number"
                                name="age"
                                id="txtPatientAge"
                                onChange={this.handleChange}
                                autoComplete="off"
                                value={this.state.formData.age}
                                onBlur={this.handleBlur}
                            />
                            <FormText>{this.state.formErrors.age}</FormText>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3}>
                            <Label for="txtPatientSex">Sex</Label>
                        </Col>
                        <Col md={9}>
                            <Input
                                type="select"
                                name="sex"
                                id="txtPatientSex"
                                onChange={this.handleChange}
                                autoComplete="off"
                                value={this.state.formData.sex}
                                // invalid={
                                //     this.state.formErrors.sex ? true : false
                                // }
                                onBlur={this.handleBlur}
                            >
                                <option value="" />
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </Input>
                            <FormText>{this.state.formErrors.sex}</FormText>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3}>
                            <Label for="txtPatientCivilStatus">
                                Civil Status
                            </Label>
                        </Col>
                        <Col md={9}>
                            <Input
                                type="select"
                                name="civil_status"
                                id="txtPatientCivilStatus"
                                onChange={this.handleChange}
                                autoComplete="off"
                                value={this.state.formData.civil_status}
                                // invalid={
                                //     this.state.formErrors.civil_status
                                //         ? true
                                //         : false
                                // }
                                onBlur={this.handleBlur}
                            >
                                <option value="" />
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                                <option value="Widow">Widow</option>
                            </Input>
                            <FormText>
                                {this.state.formErrors.civil_status}
                            </FormText>
                        </Col>
                    </Row>

                    <Row form>
                        <Col md={3}>
                            <Label for="txtPatientAddress">Address</Label>
                        </Col>
                        <Col md={9}>
                            <Input
                                type="text"
                                name="address"
                                id="txtPatientAddress"
                                onChange={this.handleChange}
                                autoComplete="off"
                                value={this.state.formData.address}
                                // invalid={
                                //     this.state.formErrors.address ? true : false
                                // }
                                onBlur={this.handleBlur}
                            />
                            <FormText>{this.state.formErrors.address}</FormText>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3} />
                        <Col md={9}>{addPatientButton}</Col>
                    </Row>
                </Form>
            </>
        );
    }
}

export default withRouter(AddPatientForm);
