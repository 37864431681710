import React, { useState } from "react";
import { Link } from "react-router-dom";

//ui components
import {
    Container,
    Col,
    FormGroup,
    Alert,
    Label,
    Input,
    Button
} from "reactstrap";

import http from "./../services/httpService";
import { apiUrl } from "./../config.json";

const Login = props => {
    const [state, setState] = useState({
        editSuccess: false,
        status: "",
        email: "",
        password: "",
        newpassword: "",
        message: ""
    });

    function handleChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    function passwordIsMatch(e) {
        e.preventDefault();

        const credentials = {
            email: state.email,
            password: state.password
        };

        console.log(credentials);
        http.post(`${apiUrl}/auth/forgot`, credentials)
            .then(response => {
                console.log(response);
                setState({
                    ...state,
                    status: response.data.message
                });
            })
            .catch(err => {
                console.log(err);
                setState({
                    ...state,
                    message: "Wrong credentials"
                });
                setTimeout(() => {
                    setState({ ...state, message: "" });
                }, 3000);
            });
    }

    function handleSubmit(e) {
        e.preventDefault();

        const data = {
            email: state.email,
            password: state.newpassword
        };

        http.put(`${apiUrl}/auth/changepassword`, data)
            .then(response => {
                console.log(response);
                setState({
                    ...state,
                    editSuccess: true
                });
                localStorage.removeItem("token");
            })
            .catch(err => console.log(err));
    }

    return (
        <div className="container-fluid pt-4" style={containerStyle}>
            {state.editSuccess ? (
                <Col className="mx-auto col-5 mt-5">
                    <Alert>
                        <h4 className="alert-heading">
                            Password Successfully Edited.
                        </h4>
                        <hr />
                        <p className=" text-center mb-0">
                            go ahead and try your new password{" "}
                            {<Link to="/login">Login</Link>}
                        </p>
                    </Alert>
                </Col>
            ) : (
                <div className="col-5 mx-auto bg-white mt-5" style={loginStyle}>
                    <Container className="p-3">
                        <Col className="mx-auto p-2">
                            <img
                                src="image/logo.png"
                                alt=""
                                // className="img-fluid"
                                height="40px"
                            />
                            <h5 className="text-center font-weight-bold">
                                Change password
                            </h5>

                            <hr />
                            <form>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="email"
                                        onChange={handleChange}
                                        value={state.email}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="password">Old Password</Label>
                                    <Input
                                        type="password"
                                        name="password"
                                        id="password"
                                        onChange={handleChange}
                                        value={state.password}
                                    />
                                </FormGroup>

                                {state.message ? (
                                    <p className="font-weight-bold text-danger">
                                        {state.message}
                                    </p>
                                ) : null}

                                <div className="text-right">
                                    <Button
                                        onClick={passwordIsMatch}
                                        color="success"
                                        size="sm"
                                    >
                                        Check Password
                                    </Button>
                                </div>

                                <FormGroup>
                                    <Label for="new-password">
                                        New Password
                                    </Label>
                                    <Input
                                        disabled={state.status.length === 0}
                                        type="password"
                                        name="newpassword"
                                        id="new-password"
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                <Button
                                    disabled={state.status.length === 0}
                                    color="primary"
                                    type="submit"
                                    size="lg"
                                    block
                                    onClick={handleSubmit}
                                >
                                    Change Password
                                </Button>
                            </form>
                        </Col>
                    </Container>
                </div>
            )}
        </div>
    );
};

const loginStyle = {
    borderRadius: "10px",
    width: "410px",
    boxShadow: "0px 9px 37px 3px rgba(0,0,0,0.19)"
};

const containerStyle = {
    backgroundImage: "url('image/background.png')",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    height: "100vh"
};

export default Login;
