import React from 'react'

const Footer = props => {
    return(
        <footer className="bg-primary mt-4">
            <div className="text-center text-white">
                <small>© 2019-2020 | Consultation Keeper, All Rights Reserved. </small>
            </div>
        </footer>
    )
}

export default Footer;
